import React, { useState } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import styled from 'styled-components';
import { FormattedMessage, useIntl, injectIntl } from 'react-intl';
import FormPopUp from '../../FormPopUp';
import * as yup from 'yup';
import yupLocales from '../../../yupLocales';
import { makeRequired, makeValidate } from '../../../utils/validation';
import FormField, { FormFieldTextarea } from '../../FormField';
import Button from '../../Button';
import submit from '../submit';

export const StyledForm = styled.form`
	label {
		display: none;
	}
	.inner {
		margin-bottom: 40px;
	}
	button {
		width: 100%;
	}
`;

const OrderMessageForm = ({ onSubmit }) => {
	const intl = useIntl();
	const [isLoading, setFormSubmitRequest] = useState(false);
	const [submitSuccess, setFormSubmitSuccess] = useState(false);
	const [submitFailed, setFormSubmitFailed] = useState(null);
	yup.setLocale(yupLocales[intl.locale]);
	const validationSchema = yup.object().shape({
		name: yup
			.string()
			.required()
			.label(intl.formatMessage({ id: 'OrderMessage.name' })),
		company: yup
			.string()
			.required()
			.label(intl.formatMessage({ id: 'OrderMessage.company' })),
		phone: yup
			.string()
			.required()
			.label(intl.formatMessage({ id: 'OrderMessage.phone' })),
		messenger: yup.string().label(intl.formatMessage({ id: 'OrderMessage.messenger' })),
		comment: yup.string().label(intl.formatMessage({ id: 'OrderMessage.comment' })),
	});

	const required = makeRequired(validationSchema);
	const validate = makeValidate(validationSchema);
	if (submitSuccess) {
		return (
			<h1>
				<FormattedMessage id="OrderMessage.submitSuccess" />
			</h1>
		);
	}
	return (
		<FinalForm
			onSubmit={values => {
				setFormSubmitRequest(true);
				setFormSubmitSuccess(false);
				setFormSubmitFailed(null);
				onSubmit(values)
					.then(res => {
						setFormSubmitRequest(false);
						setFormSubmitSuccess(true);
					})
					.catch(e => {
						setFormSubmitRequest(false);
						setFormSubmitFailed(e);
					});
			}}
			validate={validate}
		>
			{({ handleSubmit }) => (
				<StyledForm onSubmit={handleSubmit}>
					{submitFailed && (
						<h1 style={{ color: 'red' }}>
							<FormattedMessage id="OrderMessage.submitFailed" />
						</h1>
					)}
					<div className="inner">
						<FormattedMessage id="OrderMessage.name">
							{msg => <FormField required={required.name} name="name" placeholder={msg} />}
						</FormattedMessage>
						<FormattedMessage id="OrderMessage.company">
							{msg => <FormField required={required.company} name="company" placeholder={msg} />}
						</FormattedMessage>
						<FormattedMessage id="OrderMessage.phone">
							{msg => <FormField required={required.phone} name="phone" type={'tel'} placeholder={msg} />}
						</FormattedMessage>
						<FormattedMessage id="OrderMessage.messenger">
							{msg => <FormField required={required.phone} name="messenger" placeholder={msg} />}
						</FormattedMessage>
						<FormattedMessage id="OrderMessage.comment">
							{msg => (
								<FormFieldTextarea
									required={required.comment}
									name="comment"
									type={'textarea'}
									placeholder={msg}
								/>
							)}
						</FormattedMessage>
					</div>
					<p style={{ maxWidth: 350, fontSize: 14 }}>
						<FormattedMessage
							id="OrderMessage.confirm"
							defaultMessage="Нажимая на кнопку, вы даете согласие на {link}"
							values={{
								link: (
									<a href="#">
										<FormattedMessage
											id="OrderMessage.confirmLink"
											defaultMessage="обработку Ваших персональных данных"
										/>
									</a>
								),
							}}
						/>
					</p>
					<Button as="button" type="submit" disabled={isLoading}>
						{!isLoading ? (
							<FormattedMessage id="OrderMessage.btn" />
						) : (
							<FormattedMessage id="OrderMessage.submitRequest" />
						)}
					</Button>
				</StyledForm>
			)}
		</FinalForm>
	);
};
const OrderMessage = ({ isOpen, onClose, intl: { locale } }) => {
	return (
		<FormPopUp
			isOpen={isOpen}
			onClickClose={onClose}
			headerProps={{
				icon: '/img/form-message.svg',
				header: <FormattedMessage id="OrderMessage.header" defaultMessage="Заполните форму" />,
				subheader: (
					<FormattedMessage
						id="OrderMessage.subheader"
						defaultMessage="для того, чтобы мы смогли с Вами связаться"
					/>
				),
			}}
			width={'auto'}
		>
			<OrderMessageForm onSubmit={values => submit({ values, url: '/forms', type: 'message', locale })} />
		</FormPopUp>
	);
};

export default injectIntl(OrderMessage);
