import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage, injectIntl } from 'react-intl';
import TwitterIcon from 'images/svg/Fotter/Twitter.svg';
import FacebookIcon from 'images/svg/Fotter/Fb.svg';
import YouTubeIcon from 'images/svg/Fotter/YouTube.svg';
import YouTubeHoverIcon from 'images/svg/Fotter/YouTube_hover.svg';
import MediumIcon from 'images/svg/Fotter/Medium.svg';
import MediumHoverIcon from 'images/svg/Fotter/Medium_hover.svg';
import InstagramIcon from 'images/svg/Fotter/Instagram.svg';
// import InstagramHoverIcon from 'images/svg/Fotter/Instagram_hover.svg';
import GitHubIcon from 'images/svg/Fotter/GitHub.svg';

const linksIcons = {
	twitter: {
		icon: TwitterIcon,
		link: 'https://twitter.com/ubex_ai',
		color: '#1DA1F2',
		excludeLocales: ['ru'],
	},
	facebook: {
		icon: FacebookIcon,
		link: 'https://www.facebook.com/UbexAl/',
		color: '#4267B2',
		localeLinks: {
			ru: 'https://www.facebook.com/UbexAiRus',
		},
	},
	youtube: {
		icon: YouTubeIcon,
		link: 'https://www.youtube.com/channel/UCZp2qyycTugXM9v4GRMv3hQ',
		hover: YouTubeHoverIcon,
	},
	medium: {
		icon: MediumIcon,
		link: 'https://medium.com/ubex',
		color: '#000',
		hover: MediumHoverIcon,
		excludeLocales: ['ru'],
	},
	instagram: {
		icon: InstagramIcon,
		link: 'https://www.instagram.com/ubex_ai/',
		hover: InstagramIcon,
	},
	github: {
		icon: GitHubIcon,
		link: 'https://github.com/ubex-ai',
		color: '#FFFFFF',
		excludeLocales: ['ru'],
	},
};


const SocialLinksWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
`;

const SocialIconStyled = styled.svg`
	width: 1.4em;
	height: 1.4em;
	display: inline-block;
	margin-right: 2em;
	fill: #cdcdcd;
	.SocialIcon--hover {
		display: none;
	}
	&:hover {
		.SocialIcon--hover {
			display: inline-block;
		}
		use {
			fill: ${props => props.hoverFill};
		}
	}
`;

const SocialIcon = ({ icon }) => (
	<SocialIconStyled hoverFill={linksIcons[icon].color}>
		<use className={`SocialIcon--${icon}`} xlinkHref={`#${linksIcons[icon].icon.id}`} />
		{linksIcons[icon].hover && <use className={`SocialIcon--hover`} xlinkHref={`#${linksIcons[icon].hover.id}`} />}
	</SocialIconStyled>
);


const SocialLinks = ({ intl: { locale }, ...props }) => (
	<SocialLinksWrapper {...props}>
		{Object.keys(linksIcons)
			.filter(
				link =>
					!linksIcons[link].excludeLocales ||
					(linksIcons[link].excludeLocales && linksIcons[link].excludeLocales.indexOf(locale) < 0),
			)
			.map(linkKey => (
				<a
					href={
						linksIcons[linkKey].localeLinks && linksIcons[linkKey].localeLinks[locale]
							? linksIcons[linkKey].localeLinks[locale]
							: linksIcons[linkKey].link
					}
					target="_blank"
					key={linkKey}
				>
					<SocialIcon icon={linkKey} />
				</a>
			))}
	</SocialLinksWrapper>
);

export default injectIntl(SocialLinks);
