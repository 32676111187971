/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import CookieConsent from 'react-cookie-consent';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Button from 'components/Button';
// import JivoSite from 'components/JivoSite';
import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';
import NavLayer from './NavLayer';
import GridConfig from 'utils/grid';
import OrderCall from './forms/OrderCall';

const LayoutWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;

	.cookieConsent {
		position: fixed;
		background: #353535;
		color: #fff;
		z-index: 1050;
		padding: 1rem;
		border-radius: 0.5rem;
		margin: 1rem 2rem 2rem 1rem;
		display: flex;
		align-items: flex-start;
		@media (max-width: 512px) {
			display: none;
		}
		a {
			color: #767676;
			text-decoration: underline;
			&:hover {
				color: #fff;
			}
		}
		&__content {
			padding-right: 1rem;
		}
		&__button {
			border-radius: 5px;
			padding: 0.5rem 1rem;
			background-color: #e91e63;
			color: #fff;
			cursor: pointer;
			border: 0;
			white-space: nowrap;
			transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
				box-shadow 0.15s ease-in-out;
			@media (max-width: 768px) {
				display: inline-block;
			}
			&:hover {
				background-color: #c31953 !important;
				transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
					box-shadow 0.15s ease-in-out;
			}
		}
	}
`;

const SwapButton = styled(Button)`
	position: fixed;
	bottom: 10px;
	right: 10px;
`;

const steps = [`features`, `inventory`, `targets`, `partners`, `clients`, `footer`];
const fs = steps.indexOf(typeof window !== 'undefined' && window.location.hash.slice(1));
const initStep = fs < 0 ? 0 : fs;

const Layout = ({ children, transparentHeader }) => {
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const [currentStep, setStep] = useState(initStep);
	const [orderCallOpen, setOrderCallOpen] = useState(false);
	return (
		<ThemeProvider theme={{ awesomegrid: GridConfig }}>
			<LayoutWrapper>
				<Header
					onClickOpenSideBar={() => setSidebarOpen(true)}
					onClickOrderCall={() => setOrderCallOpen(true)}
					transparent={transparentHeader}
				/>
				<main>{children}</main>
				<Footer />
				<NavLayer currentStep={currentStep} steps={steps} onSetStep={i => setStep(i)} />
				<Sidebar isOpen={sidebarOpen} onClickCloseSidebar={() => setSidebarOpen(false)} />

				<OrderCall isOpen={orderCallOpen} onClose={() => setOrderCallOpen(false)} />
				{/*<TourLayout />*/}
				{/*<JivoSite id="wcZuSozhZU" />*/}
				<CookieConsent
					disableStyles
					contentClasses="cookieConsent__content"
					buttonClasses="cookieConsent__button"
					cookieName="UbexLandingCookie"
					buttonText={<FormattedMessage id="cookieConsent.agree" />}
				>
					<FormattedHTMLMessage id="cookieConsent.msg" />{' '}
					<a href="/legal/policy.pdf" target="_blank">
						<FormattedMessage id="cookieConsent.more" />
					</a>
				</CookieConsent>
				<SwapButton target="_blank" href="https://app.uniswap.org/#/swap?inputCurrency=0x6704b673c70de9bf74c8fba4b4bd748f0e2190e1">
					Swap Ubex
				</SwapButton>
			</LayoutWrapper>
		</ThemeProvider>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	transparentHeader: PropTypes.bool,
};

Layout.defaultProps = {
	transparentHeader: false,
};

export default Layout;
