import styled from 'styled-components';
import { darken } from 'polished';
import { config } from 'react-awesome-styled-grid';
import colors from 'utils/colors';

const Button = styled.a`
	font: inherit;
	font-size: 0.8em;
	border-radius: 4px;
	color: #fff;
	line-height: 1;
	font-weight: 500;
	text-decoration: none;
	display: inline-block;
	vertical-align: center;
	background: ${props => colors[props.color]};
	transition: background 0.1s linear;
	cursor: pointer;
	text-transform: uppercase;
	text-overflow: ellipsis;
	border: 0;
	&:hover {
		background: ${props => darken(0.2, colors[props.color])};
	}
	padding: 15px 15px;
	${props =>
		props.small &&
		`
			padding: 10px 15px;
		`}
	${props => config(props).media['md']`
		padding: 20px 40px;
		${props =>
			props.small &&
			`
			padding: 13px 25px;
		`}
  `}
  &.bordered {
		background: transparent;
		border: 1px solid ${props => colors[props.color]};
		color: ${props => colors[props.color]};
	}
`;

Button.defaultProps = {
	color: 'primary',
};

export default Button;
