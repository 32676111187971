import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, injectIntl } from 'react-intl';
import { linearGradient } from 'polished';
import { Container, Row, Col, config } from 'react-awesome-styled-grid';
import colors from 'utils/colors';
import ClientsScreen from '../screen-7-clients';
import CalcCampaign from '../../components/CalcCampaign';
const PartnersScreenWrapper = styled.div`
	${linearGradient({
		colorStops: [
			`${colors.lightness} 0%`,
			`${colors.lightness} 50%`,
			`${colors.light} 50%`,
			`${colors.light} 100%`,
		],
		toDirection: '-115deg',
		fallback: colors.lightness,
	})}
`;

const PartnersScreenContainer = styled(Container)`
	padding-top: 4rem;
	padding-bottom: 6rem;
	h3 {
		font-weight: bold;
		span {
			display: block;
		}
	}
	&.top {
		padding-bottom: 0;
		${props => config(props).media['md']`
			padding-bottom: 4rem;
		`}
	}
`;

const Earth = styled.div`
	background-image: url(/img/earth.svg);
	background-repeat: no-repeat;
	background-position: center;
	width: 313px;
	height: 319px;
	padding: 0px 20px 0 0;
	position: relative;
	overflow: hidden;
	@media (max-width: 1024px) {
		margin-top: 2em;
	}
	.label {
		background: #fff;
		color: #3069f4;
		font-size: 18px;
		line-height: 21px;
		padding: 8px 16px;
		position: absolute;
		border-radius: 5px;
		box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
		&#russia {
			top: 30px;
			right: 5px;
		}
		&#europe {
			top: 80px;
			right: 60px;
		}
		&#asia {
			top: 130px;
			right: 5px;
		}
		&#america {
			bottom: 60px;
			left: 20px;
		}
	}
`;

const partners = ['admixer', 'between', 'google', 'ariver', 'smarty', 'yandex', 'pubmatic', 'appflyer'];

const PartnersScreen = () => (
	<PartnersScreenWrapper>
		<PartnersScreenContainer className="top">
			<Row>
				<Col offset={{ md: 1 }}>
					<CalcCampaign />
				</Col>
			</Row>
		</PartnersScreenContainer>
		<PartnersScreenContainer id="partners">
			<Row style={{ marginBottom: '3em' }}>
				<Col md={6} offset={{ md: 1 }}>
					<div style={{ marginBottom: '2em' }}>
						<h3>
							<FormattedMessage
								id="PartnersScreen.header"
								values={{
									s: (...chunks) => <span>{chunks}</span>,
								}}
							/>
						</h3>
						<p>
							<FormattedMessage id="PartnersScreen.text" />
						</p>
					</div>
					<Row justify="center">
						{partners.map(p => (
							<Col
								key={p}
								xs={2}
								md={3}
								lg={3}
								align="center"
								justify="center"
								style={{ marginBottom: '2rem' }}
							>
								<img src={`/img/partners/${p}.png`} alt={p} />
							</Col>
						))}
					</Row>
				</Col>
				<Col md={3} offset={{ md: 1 }} align="center">
					<Earth>
						{['russia', 'europe', 'asia', 'america'].map(title => (
							<div className="label" id={title} key={title}>
								<FormattedMessage id={`PartnersScreen.earth.${title}`} defaultMessage={title} />
							</div>
						))}
					</Earth>
				</Col>
			</Row>
			<ClientsScreen />
		</PartnersScreenContainer>
	</PartnersScreenWrapper>
);
export default PartnersScreen;
