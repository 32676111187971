import React from 'react';
import PropTypes from 'prop-types';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components';
import ymReachGoal from 'utils/ymReachGoal';

const NavBarUL = styled.ul`
	display: flex;
	justify-content: space-between;
	font-weight: 300;
	${props =>
		props.vertical &&
		`
		flex-direction: column; 
	`}
	a {
		color: inherit;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
`;

const NavBarLink = ({ anchor, msgId, locale, onClick }) => (
	<li className="NavBar__item">
		<AnchorLink to={`${locale === 'en' ? '/' : `/${locale}`}#${anchor}`}>
			<span
				onClick={() => {
					typeof onClick === 'function' && onClick(anchor);
				}}
			>
				<FormattedMessage id={msgId} />
			</span>
		</AnchorLink>
	</li>
);

const NavBarOuterLink = ({ link, msgId }) => (
	<li className="NavBar__item">
		<a href={link} target="_blank">
			<FormattedMessage id={msgId} />
		</a>
	</li>
);

const videoLinks = {
	ru: 'https://www.youtube.com/watch?v=arfQNBgfZ84',
	en: 'https://www.youtube.com/watch?v=eDLj2czbgHY&list=PL_kX1zJ2zO3YEBFlmPmF8X-NmAYG6Bw3q',
};

const NavBar = ({
	vertical = false,
	intl: { locale },
	showContacts = true,
	showBuyUbex = true,
	onClickLink,
	...props
}) => (
	<NavBarUL vertical={vertical} {...props}>
		<NavBarLink locale={locale} anchor="features" msgId="NavBar.Platform" onClick={onClickLink} />
		<NavBarLink locale={locale} anchor="inventory" msgId="NavBar.Inventory" onClick={_ => ymReachGoal()} />
		<NavBarLink locale={locale} anchor="targets" msgId="NavBar.Technologies" onClick={onClickLink} />
		<NavBarLink locale={locale} anchor="partners" msgId="NavBar.Traffic" onClick={onClickLink} />
		<NavBarLink locale={locale} anchor="clients" msgId="NavBar.Partners" onClick={onClickLink} />
		{showContacts && (
			<NavBarLink locale={locale} anchor="footer" msgId="NavBar.Contacts" onClick={a => ubxTarget()} />
		)}
		<NavBarOuterLink msgId="TopScreen.VideoInstruction" link={locale === 'ru' ? videoLinks.ru : videoLinks.en} />
		{showBuyUbex && <NavBarOuterLink msgId="NavBar.BuyUbex" link="https://checkout.cryptocoin.pro/order/ubex" />}
	</NavBarUL>
);

NavBar.propTypes = {
	vertical: PropTypes.bool,
};

const ubxTarget = () => {
	ubx('AUX-00000013', 'reachGoal', 'TESTCONTACTS', { level1: { level2: { level3: '1234.56' } } });
};

export default injectIntl(NavBar);
