import React from 'react';
import YouTube from 'react-youtube';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components';
import ymReachGoal from 'utils/ymReachGoal';
import gaReachGoal from 'utils/gaReachGoal';

const VideoWrapper = styled.div`
	/* override other styles to make responsive */
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	video {
		width: 100% !important;
		height: auto !important;
	}
`;

const videoIDs = {
	en: 'WBPaCtvSpLY',
	ru: '20SQmYhAjzc',
};

const opts = {
	width: '800',
	height: '600',
	playerVars: {
		// https://developers.google.com/youtube/player_parameters
		autoplay: 1,
		rel: 0,
		showinfo: 0,
	},
};

const VideoScreen = ({ intl: { locale } }) => (
	<VideoWrapper>
		<YouTube
			videoId={videoIDs[locale]}
			opts={opts}
			onReady={e => {
				ymReachGoal('click_video_manual');
				gaReachGoal('video', 'click_video_manual');
			}}
		/>
	</VideoWrapper>
);

export default injectIntl(VideoScreen);
