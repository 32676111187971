import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from 'utils/colors';
import ReactTooltip from 'react-tooltip';

const PrettyBlockWrapper = styled.div`
	padding: 1rem;
	display: flex;
	align-items: center;
	.icon {
		background-color: #fff;
		border-radius: 100%;
		box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
		width: 60px;
		height: 60px;
		min-width: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 1em;
	}
	&.default {
		@media(max-width: 420px) {
			font-size: 12px;
			.icon {
				width: 45px;
				height: 45px;
				min-width: 45px;
			}
		}
	}
	&.big {
		font-size: 2em;
		color: ${colors.gray};
		font-weight: 900;
		justify-content: center;
		background: linear-gradient(291.66deg, #ff1268 -24.15%, #0079ff 91.39%);
		color: #fff;
		border-radius: 5px;
		@media (max-width: 1024px) {
			margin-bottom: 1em;
		}
		label {
			margin-bottom: 0;
		}
	}
	&.beta {
		position: relative;
		background: linear-gradient(291.66deg, #ff1268 -24.15%, #0079ff 91.39%);
		color: #fff;
		&:after {
			content: \'Beta\';
			display: block;
			background: ${colors.secondary};
			color: #fff;
			position: absolute;
			right: -8px;
			top: -3px;
			font-size: 12px;
			border-radius: 4px;
			font-weight: 400;
			padding: 0.2em 0.5em;
		}
	}
`;

const PrettyBlock = ({ label, isBeta, isBig, icon, tip = null }) => (
	<PrettyBlockWrapper data-tip={tip} className={`${isBig ? 'big' : 'default'} ${isBeta ? 'beta' : ''}`}>
		{icon && <div className="icon">{icon}</div>}
		<label>{label}</label>
		{tip && <ReactTooltip place="top" type="light" effect="solid" />}
	</PrettyBlockWrapper>
);

PrettyBlock.defaultProps = {
	isBeta: false,
};

PrettyBlock.propTypes = {
	label: PropTypes.string.isRequired,
	isBeta: PropTypes.bool,
	isBig: PropTypes.bool,
	icon: PropTypes.element,
};

export default PrettyBlock;
