import styled from 'styled-components';

const Overlay = styled.div`
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	display: none;
	z-index: 100;
	${props =>
		props.isOpen &&
		`
			display: block;
		`}
`;

export default Overlay;
