import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import debounce from 'utils/debounce';

const StyledCarousel = styled.div`
	margin: 0 -100px;
	-webkit-mask-image: -webkit-gradient(
		linear,
		left top,
		right top,
		color-stop(5%, rgba(0, 0, 0, 0)),
		color-stop(20%, rgba(0, 0, 0, 1)),
		color-stop(80%, rgba(0, 0, 0, 1)),
		color-stop(95%, rgba(0, 0, 0, 0)),
		color-stop(100%, rgba(0, 0, 0, 0))
	);
	.slick-slide {
		padding: 0 8px;
		box-sizing: border-box;
		text-align: center;
	}
	padding-bottom: 50px;
	.slick-arrow {
		position: absolute;
		bottom: -50px;
		width: 43px;
		height: 43px;
		border: none;
		font-size: 0;
		&.slick-prev {
			left: 50%;
			margin-left: -50px;
			background: url('/img/arrow-blue-left.png') no-repeat center;
		}
		&.slick-next {
			right: 50%;
			margin-right: -50px;
			background: url('/img/arrow-blue-right.png') no-repeat center;
		}
	}
`;

const slides = [
	{
		alt: 'pic 1',
		path: '/img/dashboard-screens/1.jpg',
	},
	{
		alt: 'pic 2',
		path: '/img/dashboard-screens/2.jpg',
	},
	{
		alt: 'pic 3',
		path: '/img/dashboard-screens/3.jpg',
	},
	{
		alt: 'pic 4',
		path: '/img/dashboard-screens/4.jpg',
	},
	{
		alt: 'pic 5',
		path: '/img/dashboard-screens/5.jpg',
	},
	{
		alt: 'pic 6',
		path: '/img/dashboard-screens/6.jpg',
	},
	{
		alt: 'pic 7',
		path: '/img/dashboard-screens/7.jpg',
	},
	{
		alt: 'pic 8',
		path: '/img/dashboard-screens/8.jpg',
	},
];

const defaultSettings = {
	centerMode: true,
	dots: false,
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	swipe: true,
	centerPadding: '350px',
	speed: 500,
	easing: 'easeInOutExpo',
};

const getCenterPadding = width => {
	let res = width / 3 - width / 10;
	if (width >= 1920) {
		res = 350;
	}
	if (width <= 1024) {
		res = 150;
	}
	if (width <= 720) {
		res = 50;
	}
	if (width <= 600) {
		res = 0;
	}
	return `${res}px`;
};

const ScreenSlider = () => {
	const [settings, setSettings] = useState(defaultSettings);
	useEffect(() => {
		const handleResize = debounce(() => {
			setSettings({
				...settings,
				centerPadding: getCenterPadding(window.innerWidth),
			});
		}, 100);
		window.addEventListener('resize', handleResize);
		return _ => {
			window.removeEventListener('resize', handleResize);
		};
	});
	useEffect(() => {
		setSettings({
			...settings,
			centerPadding: getCenterPadding(window.innerWidth),
		});
	}, []);

	return (
		<StyledCarousel>
			<Slider {...settings}>
				{slides.map(({ alt, path }) => (
					<div className="slide" key={alt}>
						<img src={path} alt={alt} />
					</div>
				))}
			</Slider>
		</StyledCarousel>
	);
};

export default ScreenSlider;
