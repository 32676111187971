'use strict';
function get(obj, path, defaultValue) {
	const result = String.prototype.split
		.call(path, /[,[\].]+?/)
		.filter(Boolean)
		.reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj);
	return result === undefined || result === obj ? defaultValue : result;
}
function set(obj, path, value) {
	if (Object(obj) !== obj) return obj; // When obj is not an object
	// If not yet an array, get the keys from the string-path
	if (!Array.isArray(path)) path = path.toString().match(/[^.[\]]+/g) || [];
	path.slice(0, -1).reduce(
		(a, c, i) =>
			Object(a[c]) === a[c] // Does the key exist and is its value an object?
				? // Yes: then follow that path
				  a[c]
				: // No: create the key. Is the next key a potential array-index?
				  (a[c] =
						Math.abs(path[i + 1]) >> 0 === +path[i + 1]
							? [] // Yes: assign a new array object
							: {}), // No: assign a new plain object
		obj,
	)[path[path.length - 1]] = value; // Finally assign the value to the last key
	return obj; // Return the top-level object to allow chaining
}

function normalizeValidationError(err, translator) {
	if (!err || !err.inner) {
		return;
	}
	return err.inner.reduce((errors, innerError) => {
		let el;

		const { path, message } = innerError;

		el = translator ? translator(innerError) : message;

		if (errors.hasOwnProperty(path)) {
			const prev = get(errors, path);
			prev.push(el);
			set(errors, path, prev);
		} else {
			set(errors, path, [el]);
		}
		return errors;
	}, {});
}

// To be passed to React Final Form
export const makeValidate = (validator, translator) => async values => {
	try {
		await validator.validate(values, { abortEarly: false });
		return {};
	} catch (err) {
		return normalizeValidationError(err, translator);
	}
};

export function makeRequired(schema) {
	const fields = schema.fields;
	return Object.keys(fields).reduce((accu, field) => {
		if (fields[field].fields) {
			accu[field] = makeRequired(fields[field]);
		} else {
			accu[field] = !!fields[field]._exclusive.required;
		}
		return accu;
	}, {});
}
