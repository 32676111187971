export default {
	dark: '#101924',
	darkness: '#0b1118',
	light: '#eeeff0',
	lightness: '#f6f6f6',
	primary: '#0079ff',
	primaryLight: '#0086ff',
	secondary: '#ff1268',
	gray: '#888c92',
	warning: '#693b94',
	info: '#3babfb',
	alt: '#786ff5',
	tariffBtn: '#3069f4',
};
