import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Container, Row, Col, Hidden, config } from 'react-awesome-styled-grid';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';

const OptOutContainer = styled(Container)`
	padding-top: calc(100px + 4rem);
	padding-bottom: 4rem;
	h1 {
		font-size: 2em;
		font-weight: bold;
		margin-bottom: 1.2em;
	}
	p {
		font-size: 1.2em;
	}
`;

function deleteCookies() {
	const allCookies = document.cookie.split(';');
	for (let i = 0; i < allCookies.length; i++)
		document.cookie = allCookies[i] + '=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

const OptOutPage = () => {
	useEffect(() => {
		deleteCookies();
	}, []);
	return (
		<Layout>
			<OptOutContainer>
				<Row>
					<Col md={8} offset={{ md: 2 }}>
						<SEO title="OptOutPage" />
						<h1>
							<FormattedMessage id="OptOutPage.header" />
						</h1>
						<p>
							<FormattedMessage id="OptOutPage.text" />
						</p>
					</Col>
				</Row>
			</OptOutContainer>
		</Layout>
	);
};

export default OptOutPage;
