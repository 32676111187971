import React from 'react';
import styled from 'styled-components';
import { linearGradient } from 'polished';
import { Container, Row, Col, Hidden, config } from 'react-awesome-styled-grid';
import { FormattedMessage, injectIntl } from 'react-intl';
import colors from 'utils/colors';
import ScreenSlider from './ScreenSlider';

const FeaturesScreenBg = styled.div`
	overflow: hidden;
	position: relative;
	${linearGradient({
		colorStops: [
			`${colors.lightness} 0%`,
			`${colors.lightness} 50%`,
			`${colors.light} 50%`,
			`${colors.light} 100%`,
		],
		toDirection: '135deg',
		fallback: '#f6f6f6',
	})}
`;

const FeaturesScreenContainer = styled(Container)`
	position: relative;
	padding-top: 6em;
	.FeaturesScreen__list {
		margin-bottom: 1em;
		font-weight: 500;
		overflow: hidden;
		li {
			float: left;
			width: 50%;
			padding: 10px 20px 10px 0;
			box-sizing: border-box;
		}
	}
	.FeaturesScreen__list-item {
		display: flex;
		align-items: center;
		margin-bottom: 1.25em;
	}
	h3 {
		font-weight: bold;
		span {
			display: block;
		}
	}

	figure {
		margin-bottom: 2rem;
	}
	figcaption {
		color: ${colors.primary};
		font-style: italic;
		font-size: 14px;
	}
`;

const FeaturesScreen = () => (
	<FeaturesScreenBg id="features">
		<FeaturesScreenContainer>
			<Row>
				<Col md={10} offset={{ md: 1 }} style={{ marginBottom: '4em' }}>
					<div className="mini-header">
						<FormattedMessage id="FeaturesScreen.header2" />
					</div>
					<h3>
						<FormattedMessage id="FeaturesScreen.header1" />
					</h3>
					<ScreenSlider />
				</Col>
			</Row>
		</FeaturesScreenContainer>
	</FeaturesScreenBg>
);

export default FeaturesScreen;
