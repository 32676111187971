import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import { Container, config } from 'react-awesome-styled-grid';
import Overlay from 'components/Overlay';
import * as PropTypes from 'prop-types';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const rollDown = keyframes`
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const PopupWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 101;
	overflow-y: auto;
	animation: 0.3s ${fadeIn} ease-out;
	${props => config(props).media['md']`
			align-items: center;
			
		`}
`;

const PopupHeader = styled.div`
	background: linear-gradient(96.14deg, #3069f4 15.01%, #ff1268 113.56%);
	padding: 35px 40px;
	color: white;
	font-size: 18px;
	display: flex;
	flex-direction: row;
	align-items: center;
	h3 {
		font-size: 20px;
		line-height: 112%;
		margin-bottom: 0;
		max-width: 250px;
		white-space: nowrap;
		${props => config(props).media['md']`
			font-size: 30px; 
		`}
	}
	.subheader {
		max-width: 250px;
	}
`;

export const PopupBody = styled.div`
	position: relative;
	border-radius: 5px;
	animation: 0.3s ${rollDown} ease-out;
	overflow: hidden;
	${props => config(props).media['md']`	
		min-width: 500px;
	`}
	${props =>
		props.width &&
		config(props).media['sm']`
		width: ${props.width};
	`}
`;

const PopupInner = styled.div`
	padding: 40px;
	color: #0b1118;
	background: #fff;
	a {
		color: #3069f4;
	}
`;

const StyledIcon = styled.div`
	width: 80px;
	height: 80px;
	background-color: #fff;
	border-radius: 100%;
	display: block;
	background-position: center;
	background-size: auto;
	background-repeat: no-repeat;
	margin-right: 1.25rem;
	${props =>
		!props.icon
			? null
			: `
		background-image: url('${props.icon}'); 
	`}
`;

const CloseBtn = styled.div`
	position: absolute;
	top: 25px;
	right: 25px;
	cursor: pointer;
	font-size: 2rem;
	z-index: 102;
	width: 15px;
	height: 15px;
	line-height: 15px;
	color: ${props => (!props.color ? 'inherit' : props.color)};
	${props =>
		!props.shadow
			? null
			: `
		text-shadow: 0px 2px 4px rgba(0,0,0,.5);
	`}
`;

class FormPopup extends Component {
	targetElement = null;
	componentDidMount() {
		// 2. Get a target element that you want to persist scrolling for (such as a modal/lightbox/flyout/nav).
		// Specifically, the target element is the one we would like to allow scroll on (NOT a parent of that element).
		// This is also the element to apply the CSS '-webkit-overflow-scrolling: touch;' if desired.
		this.targetElement = document.querySelector('body');
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		const { isOpen } = this.props;
		if (isOpen && !prevProps.isOpen) {
			disableBodyScroll(this.targetElement, { reserveScrollBarGap: true });
		}
		if (!isOpen && prevProps.isOpen) {
			enableBodyScroll(this.targetElement);
		}
	}

	componentWillUnmount() {
		clearAllBodyScrollLocks();
	}

	render() {
		const {
			children,
			headerProps: { icon, header, subheader },
			isOpen,
			onClickClose,
			width,
			closeOnClickOnWrapper,
			bodyStyle,
		} = this.props;
		return !isOpen ? null : (
			<>
				<Overlay isOpen={isOpen} />
				<PopupWrapper onClick={() => closeOnClickOnWrapper && onClickClose()}>
					<PopupBody width={width} style={bodyStyle}>
						<PopupHeader>
							{icon && <StyledIcon icon={icon} />}
							<div>
								{header && <h3>{header}</h3>}
								{subheader && <div className="subheader">{subheader}</div>}
							</div>
							<CloseBtn onClick={() => onClickClose()}>&times;</CloseBtn>
						</PopupHeader>
						<PopupInner>{children}</PopupInner>
					</PopupBody>
				</PopupWrapper>
			</>
		);
	}
}

FormPopup.propTypes = {
	children: PropTypes.element,
	isOpen: PropTypes.bool,
	onClickClose: PropTypes.func,
	width: PropTypes.string,
	closeOnClickOnWrapper: PropTypes.bool,
	bodyStyle: PropTypes.object,
};

FormPopup.defaultProps = { closeOnClickOnWrapper: false };

export default FormPopup;
