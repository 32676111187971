import styled from 'styled-components';

const ArrowBtn = styled.a`
	display: inline-block;
	cursor: pointer;
	width: 2.3rem;
	height: 2.3rem;
	background-image: url('/img/arrow-down.png');
	background-size: cover;
	opacity: ${props => (!props.disabled ? 1 : 0.5)};
`;

export const ArrowBtnUp = styled(ArrowBtn)`
	transform: rotate(180deg);
`;
export const ArrowBtnRight = styled(ArrowBtn)`
	transform: rotate(-45deg);
`;
export const ArrowBtnDown = styled(ArrowBtn)``;

export const ArrowBtnLeft = styled(ArrowBtn)`
	transform: rotate(45deg);
`;
