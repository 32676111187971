import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Visible } from 'react-awesome-styled-grid';
import { injectIntl } from 'react-intl';
import { Link } from 'gatsby';
import useOnclickOutside from 'react-cool-onclickoutside';
import ArrowDownIcon from 'images/svg/Header/Arrow_down.svg';

import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/ru';

import enFlag from 'svg-country-flags/svg/gb.svg';
import ruFlag from 'svg-country-flags/svg/ru.svg';
import deFlag from 'svg-country-flags/svg/de.svg';

const flags = { en: enFlag, ru: ruFlag, /*de: deFlag*/ };

const ArrowDownStyled = styled.svg`
	width: 1rem;
	height: 1rem;
	cursor: pointer;
	use {
		fill: none;
		stroke: #fff;
		stroke-width: 3px;
		fill-rule: evenodd;
		opacity: 0.6;
	}
	&:hover {
		use {
			opacity: 1;
		}
	}
`;
const ArrowDown = () => (
	<ArrowDownStyled>
		<use xlinkHref={`#${ArrowDownIcon.id}`} />
	</ArrowDownStyled>
);

const FlagWrapper = styled.span`
	display: inline-block;
	width: 1.25em;
	height: 1.25em;
	overflow: hidden;
	border-radius: 100%;
	margin: 0 0.5em;
	vertical-align: middle;
	cursor: pointer;
	svg {
		width: 200%;
		margin-left: -50%;
		height: 100%;
	}
`;
const Flag = ({ lang, ...props }) => (
	<FlagWrapper {...props}>
		<svg>
			<use xlinkHref={`#${flags[lang].id}`} />
		</svg>
	</FlagWrapper>
);

const LanguageSelectorWrapper = styled.div`
	position: relative;
	margin: 0 1rem;
	display: flex;
`;

const LangDropdown = styled.ul`
	background: rgba(0, 0, 0, 0.2);
	border-radius: 8px;
	position: absolute;
	z-index: 10;
	top: 100%;
	right: -0.7rem;
	list-style: none;
	margin-top: 0.5rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	a {
		display: block;
		text-decoration: none;
		color: inherit;
		padding: 0.3rem 0.85rem;
		white-space: nowrap;
		&:hover {
			background: rgba(0, 0, 0, 0.5);
		}
	}
`;

const LanguageSelector = ({ intl: { locale, defaultLocale } }) => {
	const [dropdownIsOpen, setDropdown] = useState(false);
	const ref = useRef();
	moment.locale(locale);
	useOnclickOutside(ref, () => {
		setDropdown(false);
	});

	return (
		<LanguageSelectorWrapper>
			<Visible lg xl>
				{locale.toUpperCase()}
			</Visible>
			<Flag
				lang={locale}
				onClick={() => {
					setDropdown(true);
				}}
			/>
			{dropdownIsOpen && (
				<LangDropdown ref={ref}>
					{Object.keys(flags).map(
						lang =>
							lang !== locale && (
								<li key={`lang.${lang}`}>
									<Link to={lang === defaultLocale ? '/' : `/${lang}/`}>
										{lang.toUpperCase()}
										<Flag lang={lang} />
									</Link>
								</li>
							),
					)}
				</LangDropdown>
			)}
		</LanguageSelectorWrapper>
	);
};

export default injectIntl(LanguageSelector);
