import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Container, Row, Col, Hidden, Visible, config } from 'react-awesome-styled-grid';
import NavBar from 'components/NavBar';
import colors from 'utils/colors';
import IconDownloadSVG from 'images/svg/Fotter/Icon_download.svg';
import SocialLinks from 'components/SocialLinks';
import ContactsBlock from '../ContactsBlock';

const IconStyled = styled.svg`
	width: 1.4em;
	height: 1.4em;
	display: inline-block;
	margin-right: 0.7em;
`;

const IconDownload = () => (
	<IconStyled>
		<use xlinkHref={`#${IconDownloadSVG.id}`} />
	</IconStyled>
);

const FooterWrapper = styled.footer`
	background-color: ${colors.darkness};
	color: #fff;
`;

const FooterContainer = styled(Container)`
	padding-top: 2em;
	padding-bottom: 2em;
`;

const FooterRow = styled(Row)`
	font-size: 0.77em;
	padding: 2rem 0;
	border-top: 1px solid rgb(255, 255, 255, 0.3);
	a {
		color: ${colors.secondary};
		text-decoration: underline;
	}
`;

const DownloadLinkWrapper = styled.div`
	display: flex;
	margin-bottom: 1rem;
`;
const DateNow = new Date();

const InfoBlock = ({ locale }) => {
	return (
		<>
			{locale !== 'ru' && <FormattedMessage id="Footer.info1" />}
			{['ru', 'en'].indexOf(locale) < 0 && (
				<>
					<br />
					<FormattedMessage id="Footer.info2" />
				</>
			)}
			<address>
				<FormattedMessage id="Footer.address" />
			</address>

			{locale === 'ru' &&  (<><br /></>)}
			{/*['ru', 'en'].indexOf(locale) >= 0 && (
				<FormattedMessage id="Footer.phone">{phone => <a href={`tel:${phone}`} className="phone-link">{phone}</a>}</FormattedMessage>
			)*/}
		</>
	);
};


const Footer = ({ intl }) => (
	<FooterWrapper>
		<FooterContainer id="footer">
			<Row style={{ paddingBottom: '2rem' }}>
				<Col md={2} align={{ xs: 'center', md: 'flex-start' }}>
					<Link to="/">
						<img src="/img/logo.png" alt="Ubex" />
					</Link>
				</Col>
				<Hidden sm xs>
					<Col md={9} justify="center">
						<NavBar showContacts={false} />
					</Col>
				</Hidden>
			</Row>
			<FooterRow>
				<Col xs={12} sm={12} md={2} lg={2}>
					<div style={{ marginBottom: '2em'}}>
						{DateNow.getFullYear()} &copy; Ubex AI AG.
						<br />
						<FormattedMessage id="Footer.rights" />
						<br />
						<Link to="/opt-out">Opt-out</Link>
					</div>
				</Col>
				<Col xs={6} sm={5} md={4} lg={4} align="flex-start" justify="flex-start">
					<div style={{ marginBottom: '1em'}}>
					<InfoBlock locale={intl.locale} />
					<br />
					<DownloadLinkWrapper>
						<IconDownload />
						<a href={intl.formatMessage({ id: 'Footer.termsOfUseLink' })}>
							{intl.formatMessage({ id: 'Footer.termsOfUse' })}
						</a>{' '}
						&nbsp;193 Kb
					</DownloadLinkWrapper>
					<DownloadLinkWrapper>
						<IconDownload />
						<a href={intl.formatMessage({ id: 'Footer.policyLink' })}>
							{intl.formatMessage({ id: 'Footer.policy' })}
						</a>{' '}
						&nbsp;193 Kb
					</DownloadLinkWrapper>
					</div>
				</Col>
				<Col xs={6} sm={5} md={3} lg={3}>
					<div style={{ marginBottom: '1em'}}>
						<ContactsBlock />
					</div>
				</Col>
				<Col  xs={6} sm={12}  md={3} lg={3}>
					<SocialLinks />
				</Col>
			</FooterRow>
		</FooterContainer>
	</FooterWrapper>
);

export default injectIntl(Footer);
