import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const TopicsContainer = styled('div')`
	display: flex;
	flex-wrap: wrap;
	perspective: 600px;
	justify-content: center;
`;

const StyledTopic = styled('div')`
	display: flex;
	width: 245px;
	height: 235px;
	cursor: pointer;
	position: relative;
	border-radius: 5px;
	margin-right: 10px;
	margin-bottom: 10px;
	transition: transform 1s;
	transform-style: preserve-3d;
	${props =>
		props.isFlipped &&
		`transform: rotateY(180deg);
	`}
	@media(max-width: 1356px) {
		width: 185px;
		height: 177px;
	}
	@media(max-width: 420px) {
		width: 161px;
		height: 165px;
	}
`;

const TopicFace = styled('div')`
	position: absolute;
	height: 100%;
	width: 100%;
	backface-visibility: hidden;
	border-radius: 5px;
`;

const TopicFront = styled(TopicFace)`
	background: linear-gradient(291.66deg, #ff1268 -24.15%, #0079ff 91.39%);
	display: flex;
	overflow: hidden;
`;

const TopicBack = styled(TopicFace)`
	transform: rotateY(180deg);
	background: #101924;
	color: #fff;
	padding: 20px;
	box-sizing: border-box;
	@media(max-width: 1356px) {
		padding: 10px;
	}
	ul {
		font-size: 14px;
		li {
			margin-bottom: 8px;
		}
		@media(max-width: 1356px) {
			font-size: 12px;
			li {
				margin-bottom: 5px;
			}
		}
	}
`;

const TopicName = styled('div')`
	text-transform: uppercase;
	font-size: 18px;
	margin-left: 20px;
	margin-bottom: 20px;
	@media(max-width: 1356px) {
		font-size: 14px;
	}
`;

const TopicImg = styled('div')`
	display: flex;
	width: 100%;
	height: 100%;
	align-items: flex-end;
	justify-content: flex-start;
	background-position: 35px -90px;
	background-repeat: no-repeat;
	@media(max-width: 420px) {
		background-position: 60px -50px;
		background-size: 110%;
	}
`;

const BackHeader = styled('h3')`
	text-transform: uppercase;
	font-size: 18px;
	@media(max-width: 1356px) {
		font-size: 14px;
	}
	&::after {
		content: '';
		display: block;
		height: 1px;
		width: 80px;
		margin-top: 10px;
		background: linear-gradient(291.66deg, #ff1268 -24.15%, #0079ff 91.39%);	
		@media(max-width: 1356px) {
			margin-top: 8px;
		}
	}
`;

const topics = [
	{
		name: 'estate',
		image: 'topic_estate.png',
		color: '#5513AB',
		Description: () => (
			<ul>
				<li>
					<FormattedMessage id="Topic.estate.description_1" defaultMessage="Просмотр карточки/планировки" />
				</li>
				<li>
					<FormattedMessage
						id="Topic.estate.description_2"
						defaultMessage="Переход на страницу ипотека и условия"
					/>
				</li>
				<li>
					<FormattedMessage
						id="Topic.estate.description_3"
						defaultMessage="Нажатие на кнопку «заказать звонок»"
					/>
				</li>
			</ul>
		),
	},
	{
		name: 'finance',
		image: 'topic_finance.png',
		color: '#3069F4',
		Description: () => (
			<ul>
				<li>
					<FormattedMessage
						id="Topic.finance.description_1"
						defaultMessage="Использование онлайн-калькулятора"
					/>
				</li>
				<li>
					<FormattedMessage id="Topic.finance.description_2" defaultMessage="Звонок в банк" />
				</li>
				<li>
					<FormattedMessage id="Topic.finance.description_3" defaultMessage="Заполненная анкета" />
				</li>
				<li>
					<FormattedMessage id="Topic.finance.description_4" defaultMessage="Скачивание приложения банка" />
				</li>
			</ul>
		),
	},
	{
		name: 'auto',
		image: 'topic_auto.png',
		color: '#FF1268',
		Description: () => (
			<ul>
				<li>
					<FormattedMessage id="Topic.auto.description_1" defaultMessage="Использование конфигуратора авто" />
				</li>
				<li>
					<FormattedMessage id="Topic.auto.description_2" defaultMessage="Переход на страницу «дилеры»" />
				</li>
				<li>
					<FormattedMessage id="Topic.auto.description_3" defaultMessage="Запись на тест-драйв" />
				</li>
				<li>
					<FormattedMessage id="Topic.auto.description_4" defaultMessage="Посещение дилера" />
				</li>
			</ul>
		),
	},
	{
		name: 'ecommerce',
		image: 'topic_ecommerce.png',
		color: '#4E00D4',
		Description: () => (
			<ul>
				<li>
					<FormattedMessage
						id="Topic.ecommerce.description_1"
						defaultMessage="Переход на страницу «специальное предложение»"
					/>
				</li>
				<li>
					<FormattedMessage
						id="Topic.ecommerce.description_2"
						defaultMessage="Переход на страницу «каталог»"
					/>
				</li>
				<li>
					<FormattedMessage
						id="Topic.ecommerce.description_3"
						defaultMessage="Переход на страницу «перейти в корзину»"
					/>
				</li>
			</ul>
		),
	},
	{
		name: 'pharma',
		image: 'topic_pharma.png',
		color: '#B144D7',
		Description: () => (
			<ul>
				<li>
					<FormattedMessage
						id="Topic.pharma.description_1"
						defaultMessage="Переход на сайт интернет-аптеки"
					/>
				</li>
				<li>
					<FormattedMessage
						id="Topic.pharma.description_2"
						defaultMessage="Переход на страницу «где купить»"
					/>
				</li>
				<li>
					<FormattedMessage
						id="Topic.pharma.description_3"
						defaultMessage="Визиты продолжительностью 2+ минуты"
					/>
				</li>
			</ul>
		),
	},
	{
		name: 'fmcg',
		image: 'topic_fmcg.png',
		color: '#094FF0',
		Description: () => (
			<ul>
				<li>
					<FormattedMessage id="Topic.fmcg.description_1" defaultMessage="Переход на онлайн-ритейл" />
				</li>
				<li>
					<FormattedMessage id="Topic.fmcg.description_2" defaultMessage="Переход на страницу «где купить»" />
				</li>
				<li>
					<FormattedMessage id="Topic.fmcg.description_3" defaultMessage="Покупка оффлайн" />
				</li>
			</ul>
		),
	},
];

const TopScreenTopics = ({ onMouseEnter, onMouseLeave }) => {
	const [flippedCard, setFlippedCard] = React.useState(null);
	return (
		<TopicsContainer onMouseLeave={() => onMouseLeave()}>
			{topics.map(({ name, image, color, Description }) => (
				<StyledTopic
					key={name}
					onClick={() => setFlippedCard(name)}
					isFlipped={flippedCard === name}
					onMouseEnter={() => {
						onMouseEnter(color);
						setFlippedCard(name);
					}}
					onMouseLeave={() => {
						setFlippedCard(null);
					}}
				>
					<TopicFront>
						<TopicImg style={{ backgroundImage: `url(/img/top-screen-topics/${image})` }}>
							<TopicName>
								<FormattedMessage id={`TopScreenTopics.${name}`} defaultMessage={name} />
							</TopicName>
						</TopicImg>
					</TopicFront>
					<TopicBack>
						<BackHeader>
							<FormattedMessage id={`TopScreenTopics.${name}`} defaultMessage={name} />
						</BackHeader>
						<Description />
					</TopicBack>
				</StyledTopic>
			))}
		</TopicsContainer>
	);
};

export default TopScreenTopics;
