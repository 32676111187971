export default [
	{
		title: 'Авто',
		description:
			"Известный японский производитель автомобилей",
		bg: '/img/cases/bg/auto.png',
		runtime: ['01.03.2021', '30.04.2021'],
		region: 'Россия',
		segments: ['Покупка автомобиля', 'Автомобили типа «Кроссовер»', 'Автомобили типа «Универсал»', 'Полноприводные автомобили'],
		format: ['Desktop + Mobile'],
		instruments: ['White list', 'Аудиторные закупки'],
		results: {
			impressions: '13 240 201',
			clicks: '20 002',
			ctr: '0,15',
			visits: '14 503',
			pi: '72,5',
			reach: '5 423 941',
			// frequency: 2.44,
			br: '14,49',
			deep: '3,395',
			time: '00:03:42',
		},
	},
	{
		title: 'Мода',
		description:
			"Австрийский бренд женской одежды премиум класса",
		bg: '/img/cases/bg/fashion.png',
		runtime: ['26.02.2021', '28.03.2021'],
		region: 'Москва, Санкт-Петербург, города миллионники',
		segments: ['Мода и стиль', 'Покупки', 'Женские интересы и здоровье', 'Фитнес и Красота', 'Правильное питание', 'Романтические отношения'],
		format: ['Desktop + Mobile'],
		instruments: ['White list', 'Аудиторные закупки'],
		results: {
			impressions: '3 628 031',
			clicks: '6 871',
			ctr: '0,19',
			visits: '5 461',
			pi: '79,47',
			reach: '1 511 680',
			// frequency: 2.4,
			br: '35,4',
			deep: '3,41',
			time: '00:03:48',
		},
	},
	{
		title: 'Pharma',
		description:
			"Швейцарский производитель товаров для молодых мам",
		bg: '/img/cases/bg/pharma.png',
		runtime: ['05.04.2021', '30.04.2021'],
		region: 'Москва, Санкт-Петербург, города миллионники',
		segments: ['Беременность и материнство', 'Уход за младенцами и детская гигиена'],
		format: ['Desktop + Mobile'],
		instruments: ['White list', 'Аудиторные закупки'],
		results: {
			impressions: '1 500 286',
			clicks: '1 848',
			ctr: '0,12',
			visits: '1 194',
			pi: '64,9',
			reach: '1 201 243',
			// frequency: 1.4,
			br: '15,8',
			deep: '2,35',
			time: '00:00:51',
		},
	},
	{
		title: 'SM-KLINIKA',
		description:
			"The SM-Doctor network of private children's clinics is part of the large medical holding SM-KLINIKA, which unites medical centers in Moscow, the Moscow region and St. Petersburg.",
		logo: '/img/cases/sm-klinika.png',
		bg: '/img/cases/bg/sm-klinika.png',
		runtime: ['01.10.2020', ' 26.10.2020'],
		region: 'Russia',
		segments: ['Sites for mothers', 'Medical sites'],
		format: ['Display Banners'],
		instruments: ['White list'],
		results: {
			cpm: 1.65,
			cpc: 2.3,
			ctr: 0.9,
		},
	},
	{
		title: 'Libertex',
		description: 'Libertex is an international online forex and CFD brokerage brand.',
		logo: '/img/cases/libertex.png',
		bg: '/img/cases/bg/libertex.png',
		runtime: ['28.05.2020', '05.06.2020'],
		region: 'Worldwide',
		segments: ['Finance', 'Crypto currencies', 'Forex'],
		format: ['Display Banners'],
		instruments: ['White list'],
		results: {
			cpm: 0.15,
			cpc: 0.09,
			ctr: 0.17,
		},
	},
	{
		title: 'Bet9ja',
		description:
			'Bet9ja is the largest bookmaker in Nigeria, targeting players from this country. It has a wide betting line in 30 sports, high odds and a modern interface.',
		logo: '/img/cases/bet9ja.png',
		bg: '/img/cases/bg/bet9ja.png',
		runtime: ['05.2020', '07.2020'],
		region: 'Nigeria',
		segments: ['Sports', 'Entertainment', 'Hobbies'],
		format: ['All types of traffic'],
		instruments: ['White list'],
		results: {
			cpm: 1,
			cpc: 0.13,
			ctr: 0.75,
		},
	},
	//
	// {
	// 	title: 'Alfa Bank',
	// 	description:
	// 		'Alfa Bank is the largest private bank in Russia and one of the top five credit banks in Russia by volume according to the ratings of RBC and Forbes.',
	// 	logo: '/img/cases/alfa.png',
	// 	bg: '/img/cases/bg/alfa.png',
	// 	runtime: ['05.03.2020', '13.03.2020'],
	// 	region: 'Russia',
	// 	segments: ['Personal finance', 'Loans', 'Business'],
	// 	format: ['Display Banners'],
	// 	instruments: ['White list'],
	// 	results: {
	// 		cpm: 0.32,
	// 		cpc: 0.37,
	// 		ctr: 0.09,
	// 	},
	// },
	{
		title: 'RIOT GAMES',
		description: 'Video game developer, publisher, and esports tournament organizer based in California.',
		logo: '/img/cases/riot.png',
		bg: '/img/cases/bg/riot.png',
		runtime: ['13.04.2020', '26.04.2020'],
		region: 'Russia and CIS',
		segments: ['Gambling', 'Entertainment', 'Hobbies'],
		format: ['Display Banners'],
		instruments: ['White list'],
		results: {
			cpm: 0.69,
			cpc: 0.46,
			ctr: 0.15,
		},
	},
	{
		title: 'Volvo',
		description:
			'Volvo is a concern that manufactures commercial vehicles, trucks, buses, engines and various equipment.',
		logo: '/img/cases/volvo.png',
		bg: '/img/cases/bg/volvo.png',
		runtime: ['10.02.2020', '20.02.2020'],
		region: 'Russia',
		segments: ['Cars', 'Business', 'Personal finance'],
		format: ['Display Banners'],
		instruments: ['White list'],
		results: {
			cpm: 1,
			cpc: 0.52,
			ctr: 0.19,
		},
	},
	{
		title: '585 Gold',
		description: 'Leading jewelry chain in Russia',
		logo: '/img/cases/585gold.png',
		bg: '/img/cases/bg/585gold.png',
		runtime: ['01.2020'],
		region: 'Russia',
		segments: ['Entrepreneurship', 'Business', 'Jewelry', 'Beauty'],
		format: ['Display Banners'],
		instruments: ['White list'],
		results: {
			cpm: 1.65,
			cpc: 0.69,
			ctr: 0.9,
		},
	},
	{
		title: 'ESPORTS',
		description:
			'3D Ball Crusher is an indie game by MobilAp studio having more than 10 000 installs on Google Play',
		logo: '/img/cases/esports.png',
		bg: '/img/cases/bg/esports.png',
		runtime: ['9.06.2020', '14.06.2020'],
		region: 'Russia',
		segments: ['Gambling', 'Entertainment', 'Hobbies'],
		format: ['Display Banners'],
		instruments: ['White list'],
		results: {
			cpm: 2.67,
			cpc: 0.07,
			ctr: 3.78,
		},
	},
	{
		title: 'Quppy',
		description: 'World licensed multicurrency digital payment solution operating since 2017',
		logo: '/img/cases/quppy.png',
		bg: '/img/cases/bg/quppy.png',
		runtime: ['16.06.2020', '22.06.2020'],
		region: 'European Union',
		segments: ['Personal finance', 'Loans', 'Business'],
		format: ['In-app, banners'],
		instruments: ['White list'],
		results: {
			cpm: 0.94,
			cpc: 0.23,
			ctr: 0.42,
		},
	},
	{
		title: 'Winline',
		description:
			"The Winline Bookmaker's Office is recognised by many rating websites as one of the best companies to bet on sports in Russia. We offer high quotations for many outcomes and provide detailed player statistics and video broadcasts for live betting.",
		logo: '/img/cases/winline.png',
		bg: '/img/cases/bg/winline.png',
		runtime: ['16.06.2020', '22.06.2020'],
		region: 'Russia',
		segments: ['Sports', 'Entertainment', 'Hobbies'],
		format: ['Iphones only, in-app traffic'],
		instruments: ['White list'],
		results: {
			cpm: 2,
			cpc: 0.89,
			ctr: 0.22,
		},
	},
];
