import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Container, Row } from 'react-awesome-styled-grid';

const OpScreenFormat = styled.div`
	font-size: 14px;
	background-repeat: no-repeat;
	position: relative;
	text-align: center;
	min-height: 100%;
	padding-right: 10px;
	img {
		margin-bottom: 1em;
	}
	h5 {
		color: #3069f4;
		font-weight: 700;
		font-size: 30px;
		line-height: 35px;
		margin-bottom: 0.5em;
	}
	&::after {
		content: '';
		display: block;
		width: 1px;
		background: linear-gradient(291.66deg, #ff1268 -24.15%, #0079ff 91.39%);
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		@media(max-width: 420px) {
			display: none;
		}
	}
`;


const OpScreenFormatsWrapper = styled(Row)`
	margin-bottom: 5em;
	& > div:last-child {
		& > div::after {
			display: none;
		}
	}
`;

const OpScreenFormats = () => {
	return (
		<OpScreenFormatsWrapper>
			<Col lg={3} md={3} sm={6} xs={12}>
				<OpScreenFormat>
					<img src="/img/formats/format-banners.svg" alt=""/>
					<h5>Banners</h5>
					<p>
						<FormattedMessage id="OpportunitiesScreen.formatDisplay" />
					</p>
					<p>Dеsktop, Mobile Web, In-App</p>
				</OpScreenFormat>
			</Col>
			<Col lg={3} md={3} sm={6} xs={12}>
				<OpScreenFormat>
					<img src="/img/formats/format-video.svg" alt=""/>
					<h5>Video</h5>
					<p>In Stream / out-Stream</p>
					<p>Dеsktop, Mobile Web, In-App</p>
				</OpScreenFormat>
			</Col>
			<Col lg={3} md={3} sm={6} xs={12}>
				<OpScreenFormat>
					<img src="/img/formats/format-inapp.svg" alt=""/>
					<h5>In App</h5>
					<p>
						<FormattedMessage id="OpportunitiesScreen.formatInapp" />
					</p>
				</OpScreenFormat>
			</Col>
			<Col lg={3} md={3} sm={6} xs={12}>
				<OpScreenFormat>
					<img src="/img/formats/format-inapp.svg" alt=""/>
					<h5>White Label</h5>
					<p>
						<FormattedMessage id="OpportunitiesScreen.formatWL" />
					</p>
				</OpScreenFormat>
			</Col>
		</OpScreenFormatsWrapper>
	);
};

export default OpScreenFormats;
