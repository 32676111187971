import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
const StyledCarousel = styled.div`
	background: #ddd;
	padding: 10px 0 5px;
	.logo {
		padding: 0 10px;
		box-sizing: border-box;
		img {
			max-height: 80px;
			margin: 0 auto;
			opacity: 0.5;
			filter: brightness(0.5) grayscale(100%);
			&:hover {
				opacity: 1;
				filter: brightness(1) grayscale(0);
			}
		}
	}
`;

const settings = {
	dots: false,
	infinite: true,
	slidesToShow: 5,
	arrows: false,
	slidesToScroll: 1,
	autoplay: true,
	speed: 10000,
	autoplaySpeed: 1,
	cssEase: 'linear',
	pauseOnHover: false,
	swipe: false,
};

const sites = [
	{
		name: 'BILD',
		path: '/img/sites/BILD.png',
	},
	{
		name: 'blockchain',
		path: '/img/sites/blockchain.png',
	},
	{
		name: 'BuzzFeed',
		path: '/img/sites/BuzzFeed.png',
	},
	{
		name: 'CNN',
		path: '/img/sites/cnn.png',
	},
	{
		name: 'CoinMarketCap',
		path: '/img/sites/coinmarketcap.png',
	},
	{
		name: 'Forbes',
		path: '/img/sites/forbes.png',
	},
	{
		name: 'Fox News',
		path: '/img/sites/Fox_News.png',
	},
	{
		name: 'Investing.com',
		path: '/img/sites/investing.png',
	},
	{
		name: 'MSN',
		path: '/img/sites/MSN.png',
	},
	{
		name: 'reddit',
		path: '/img/sites/reddit.png',
	},
	{
		name: 'Spotify',
		path: '/img/sites/Spotify.png',
	},
	{
		name: 'the Guardian',
		path: '/img/sites/theguardian.png',
	},
	{
		name: 'vk',
		path: '/img/sites/vk.png',
	},
	{
		name: 'yahoo',
		path: '/img/sites/yahoo.png',
	},
];
const LogoCarousel = () => (
	<StyledCarousel>
		<Slider {...settings}>
			{sites.map(({ name, path }) => (
				<div className="logo" key={name}>
					<img src={path} alt={name} />
				</div>
			))}
		</Slider>
	</StyledCarousel>
);

export default LogoCarousel;
