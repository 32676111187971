import https from 'https';
import axios from 'axios';

const port = 3001;
const host = process.env.APP_HOST || 'http://localhost';
const agent = new https.Agent({
	rejectUnauthorized: false,
});

export default ({ values, url = '/', type, locale }) =>
	axios.post(`${host}:${port}${url}`, { ...values, locale: locale, type }, { httpsAgent: agent });
