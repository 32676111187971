import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, injectIntl } from 'react-intl';
import { linearGradient } from 'polished';
import { Row, Col, Hidden, Visible } from 'react-awesome-styled-grid';
import colors from 'utils/colors';
import Button from 'components/Button';
import PartnerPopUp from 'components/PartnerForm';

const ClientsScreenWrapper = styled.div`
	${linearGradient({
		colorStops: [
			`${colors.lightness} 0%`,
			`${colors.lightness} 50%`,
			`${colors.light} 50%`,
			`${colors.light} 100%`,
		],
		toDirection: '-115deg',
		fallback: colors.lightness,
	})}
`;

// logos from /static/img/clients
const clients = ['arrowmedia', 'nectarin', 'mgc', 'realweb', 'sber', 'igronik', 'aidadigital'];

const ClientsScreenBtn = ({ onClick }) => (
	<Button as="button" color="primary" target="_blank" onClick={e => onClick(e)} style={{ marginBottom: '1em' }}>
		<FormattedMessage id="ClientsScreen.btn" />
	</Button>
);

const presLinks = {
	en: '/presentation/Ubex_present_ENG.pdf',
	ru: '/presentation/Ubex_present_RU.pdf',
};

const PresBtn = ({ locale }) => (
	<Button color="secondary" className="bordered" target="_blank" href={presLinks[locale] || presLinks.en}>
		<FormattedMessage id="ClientsScreen.PresBtn" /> (.pdf)
	</Button>
);

const ClientsScreen = ({ intl: { locale } }) => {
	const [popUpIsOpen, togglePopUp] = useState(false);
	return (
		<>
			<PartnerPopUp isOpen={popUpIsOpen} onClickClose={_ => togglePopUp(false)} />
			<Row>
				<Col md={10} offset={{ md: 1 }} id="clients">
					<Row justify="flex-start">
						<Col md={6} justify="center" align="flex-start">
							<h3>
								<FormattedMessage
									id="ClientsScreen.header"
									values={{
										s: (...chunks) => <span>{chunks}</span>,
									}}
								/>
							</h3>
							<Hidden xs sm>
								<Row>
									<Col>
										<div>
										<ClientsScreenBtn onClick={e => togglePopUp(true)} />
										</div>
									</Col>
									<Col>
										<div>
										<PresBtn locale={locale} />
										</div>
									</Col>
								</Row>
							</Hidden>
						</Col>
						<Col md={4} offset={{ md: 1 }} justify="center" align="center">
							<Row style={{ flexGrow: 0}} justify="center">
								{clients.map(c => (
									<Col
										key={c}
										xs={2}
										sm={4}
										md={4}
										justify="center"
										align="center"
										style={{ marginBottom: '2rem' }}
									>
										<img src={`/img/clients/${c}.png`} alt={c} />
									</Col>
								))}
							</Row>
						</Col>
					</Row>
					<Visible xs sm>
						<Row>
							<Col align="center">
								<ClientsScreenBtn onClick={e => togglePopUp(true)} />
							</Col>
							<Col align="center">
								<PresBtn locale={locale} />
							</Col>
						</Row>
					</Visible>
				</Col>
			</Row>
		</>
	);
};
export default injectIntl(ClientsScreen);
