export default {
	mediaQuery: 'only screen',
	columns: {
		xs: 4,
		sm: 12,
		md: 12,
		lg: 12,
		xl: 12,
	},
	gutterWidth: {
		xs: 1,
		sm: 1,
		md: 1,
		lg: 1,
		xl: 1,
	},
	paddingWidth: {
		xs: 1,
		sm: 1,
		md: 1,
		lg: 1,
		xl: 1,
	},
	container: {
		xs: 'full', // 'full' = max-width: 100%
		sm: 'full', // 'full' = max-width: 100%
		md: 'full', // 'full' = max-width: 100%
		lg: 90, // max-width: 1440px
		xl: 90, // max-width: 1440px
	},
	breakpoints: {
		xs: 1,
		sm: 48, // 768px
		md: 64, // 1024px
		lg: 90, // 1440px
		xl: 120, // 1920px
	},
};
