import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const ContactsBlockWrapper = styled.dl`
	overflow: hidden;
	dt,
	dd {
		float: left;
		width: 7rem;
		padding-bottom: 0.6rem;
	}
	dt {
		clear: left;
	}
`;

const ContactsBlock = props => (
	<ContactsBlockWrapper {...props}>
		<dt>
			<FormattedMessage id="ContactsBlock.label1" />:
		</dt>
		<dd>
			<a href="mailto: support@ubex.com">support@ubex.com</a>
		</dd>
		<dt>
			<FormattedMessage id="ContactsBlock.label2" />:
		</dt>
		<dd>
			<a href="mailto: partners@ubex.com">partners@ubex.com</a>
		</dd>
		<dt>
			<FormattedMessage id="ContactsBlock.label3" />:
		</dt>
		<dd>
			<a href="mailto: press@ubex.com">press@ubex.com</a>
		</dd>
		<dt>
			<FormattedMessage id="ContactsBlock.label4" />:
		</dt>
		<dd>
			<a href="mailto: sales@ubex.com">sales@ubex.com</a>
		</dd>
	</ContactsBlockWrapper>
);

export default ContactsBlock;
