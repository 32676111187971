import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Slider from 'react-slick';
import styled from 'styled-components';
import { linearGradient } from 'polished';
import { Container, Row, Col, config } from 'react-awesome-styled-grid';
import colors from 'utils/colors';
import debounce from 'utils/debounce';
import cases from './cases';
import CaseResults from './CaseResults';

const BGScreenWrapper = styled.div`
	color: #fff;
	${linearGradient({
		colorStops: [
			`${colors.darkness} 0%`,
			`${colors.darkness} 35%`,
			`${colors.dark} 35%`,
			`${colors.dark} 75%`,
			`${colors.darkness} 75%`,
			`${colors.darkness} 100%`,
		],
		toDirection: '115deg',
		fallback: colors.dark,
	})}
	padding-top: 5em;
	padding-bottom: 6em;
	h3 {
		text-align: center;
	}
`;
const StyledCarousel = styled.div`
	.slick-track {
		display: flex !important;
	}
	.slick-slide {
		padding: 0 8px;
		box-sizing: border-box;
		height: inherit !important;
		text-align: center;
		& > div {
			height: 100%;
		}
	}
	padding-bottom: 100px;
	.slick-arrow {
		position: absolute;
		bottom: -100px;
		width: 43px;
		height: 43px;
		border: none;
		font-size: 0;
		&.slick-prev {
			left: 50%;
			margin-left: -50px;
			background: url('/img/arrow-left.png') no-repeat center;
		}
		&.slick-next {
			right: 50%;
			margin-right: -50px;
			background: url('/img/arrow-right.png') no-repeat center;
		}
	}
`;

const StyledSlide = styled.div`
	border-radius: 5px;
	overflow: hidden;
	text-align: left;
	height: 100%;
	& > div {
		height: 100%;
	}
	h3 {
		text-align: left;
	}
`;

const StyledLeftCol = styled(Col)`
	background-repeat: no-repeat;
	background-position: center;
	position: relative;
	background-size: cover;
	color: white;
	padding: 70px 40px;
	h3 {
		font-weight: bold;
		position: relative;
	}
	p {
		position: relative;
	}
	.logo {
		position: absolute;
		bottom: 0;
		border-top-right-radius: 5px;
		border-top-left-radius: 5px;
		background: white;
		padding: 5px;
	}
	&:before {
		content: '';
		width: 100%;
		height: 100%;
		background: linear-gradient(180deg, rgba(255, 18, 104, 0.7) 0%, rgba(0, 121, 255, 0.7) 100%);
		position: absolute;
		top: 0;
		left: 0;
		display: block;
	}
`;
const StyledRightCol = styled(Col)`
	background-color: white;
	color: #000;
	padding: 30px;

	.runtime {
		font-size: 12px;
		font-weight: bold;
	}
	.runtime + .params {
		margin-top: 1em;
	}
	.params {
		margin-top: 2em;
	}
	.param {
		margin-bottom: 1em;
	}
	
	dd {
		margin-bottom: 1em;
	}
`;

const defaultSettings = {
	centerMode: true,
	dots: false,
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	swipe: true,
	speed: 500,
	initialSlide: 0,
	centerPadding: '0px',
	// cssEase: 'cubic-bezier(0.68, -0.6, 0.32, 1.6)',
	// easing: 'cubic-bezier(0.68, -0.6, 0.32, 1.6)'
};

const getCenterPadding = width => {
	let res = width / 3 - width / 10;
	if (width >= 1920) {
		res = 600;
	}
	if (width <= 1024) {
		res = 150;
	}
	if (width <= 720) {
		res = 50;
	}
	if (width <= 600) {
		res = 0;
	}
	return `${res}px`;
};
const CasesScreen = () => {
	const [settings, setSettings] = useState(defaultSettings);
	useEffect(() => {
		const handleResize = debounce(() => {
			setSettings({
				...settings,
				centerPadding: getCenterPadding(window.innerWidth),
			});
		}, 100);
		window.addEventListener('resize', handleResize);
		return _ => {
			window.removeEventListener('resize', handleResize);
		};
	});
	useEffect(() => {
		setSettings({
			...settings,
			centerPadding: getCenterPadding(window.innerWidth),
		});
	}, []);
	return (
		<BGScreenWrapper>
			<h3>
				<FormattedMessage id="Cases.header" defaultMessage="Cases" />
			</h3>
			<StyledCarousel>
				<Slider {...settings}>
					{cases.map(el => (
						<StyledSlide key={el.title}>
							<Row>
								<StyledLeftCol
									noGutter
									xs={6}
									md={6}
									lg={6}
									style={{ backgroundImage: `url(${el.bg})` }}
								>
									<h3>{el.title}</h3>
									<p>{el.description}</p>
									{el.logo && <img src={el.logo} className="logo" alt={el.title} />}
								</StyledLeftCol>
								<StyledRightCol noGutter xs={6} md={6} lg={6}>
									{el.runtime.length > 0 && (
										<div className="runtime">
											<FormattedMessage id="Cases.runtime" defaultMessage="Campaign runtime" />:{' '}
											<div>{el.runtime.join(' — ')}</div>
										</div>
									)}
									<div className="params">
										<div className="param">
											<strong><FormattedMessage id="Cases.region" defaultMessage="Region" />:</strong>{' '}{el.region}
										</div>
										<div className="param">
											<strong><FormattedMessage id="Cases.segments" defaultMessage="Selected segments" />:</strong>{' '}
											{el.segments.join(', ')}
										</div>
										<div className="param">
											<strong><FormattedMessage id="Cases.format" defaultMessage="Format of creatives" />:</strong><br />
											{el.format.join(', ')}
										</div>
										<div className="param">
											<strong><FormattedMessage id="Cases.instruments" defaultMessage="Instruments" />:</strong><br />
											{el.instruments.join(', ')}
										</div>
									</div>
									<CaseResults results={el.results} />
								</StyledRightCol>
							</Row>
						</StyledSlide>
					))}
				</Slider>
			</StyledCarousel>
		</BGScreenWrapper>
	);
};

export default CasesScreen;
