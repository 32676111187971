import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Container, Row, Col, config } from 'react-awesome-styled-grid';
import colors from 'utils/colors';
import PrettyBlock from './PrettyBlock';
import TargetIcon1 from 'images/svg/Block_3/Icon_3_1.svg';
import TargetIcon2 from 'images/svg/Block_3/Icon_3_2.svg';
import TargetIcon3 from 'images/svg/Block_3/Icon_3_3.svg';
import TargetIcon4 from 'images/svg/Block_3/Icon_3_4.svg';
import TargetIcon5 from 'images/svg/Block_3/Icon_3_5.svg';
import TargetIcon6 from 'images/svg/Block_3/Icon_3_6.svg';
import TargetIcon7 from 'images/svg/Block_3/Icon_3_7.svg';
import TargetIcon8 from 'images/svg/Block_3/Icon_3_8.svg';

const TargetsScreenWrapper = styled.div`
	background-color: #f6f6f6;
	background-image: url('/img/line-chart-4.png');
	background-repeat: no-repeat;
	background-position: left calc(50% - 50px);
	@media(max-width: 420px) {
		background-image: none;
	}
`;

const TargetsScreenContainer = styled(Container)`
	position: relative;
	padding-top: 5em;
	padding-bottom: 3em;
	${props => config(props).media['md']`
		padding-bottom: 7em;
	`}
	h3 {
		font-weight: bold;
	}
	h4 {
		color: ${colors.secondary};
	}
`;

const BlocksRow = styled(Row)`
	margin-bottom: 0em;
`;

const PrettyBlockGriding = styled(Col)`
	margin-bottom: 0em;
`;

const icons = [TargetIcon1, TargetIcon2, TargetIcon3, TargetIcon4, TargetIcon5, TargetIcon6, TargetIcon7, TargetIcon8];
const StyledIcon = styled.svg`
	width: 2.2em;
	height: 2.2em;
	vertical-align: middle;
	fill: #3069f4;
`;
const TargetIcon = ({ n }) => (
	<StyledIcon>
		<use xlinkHref={`#${icons[n].id}`} />
	</StyledIcon>
);

const targetBlocks = ['deviceType', 'preference', 'deviceBrand', 'timeFrame', 'action', 'browser', 'geo', 'audience'];
const optBlocks = ['CPM', 'CPC', 'vCPM', 'CPA'];

const TargetsScreen = () => (
	<TargetsScreenWrapper>
		<TargetsScreenContainer id="targets">
			<Row style={{ marginBottom: '4em' }}>
				<Col md={4} offset={{ md: 1 }}>
					<div style={{ marginBottom: '3rem' }}>
						<div className="mini-header">
							<FormattedMessage id="TargetsScreen.miniheader" />
						</div>
						<h3>
							<FormattedMessage
								id="TargetsScreen.header1"
								values={{
									s: (...chunks) => <strong>{chunks}</strong>,
									v: 15,
								}}
							/>
						</h3>
						<p>
							<FormattedMessage id="TargetsScreen.text1" />
						</p>
					</div>
				</Col>
				<Col md={6} offset={{ md: 1 }}>
					<h4>
						<FormattedMessage id="TargetsScreen.subheader1" />
					</h4>
					<BlocksRow>
						{targetBlocks.map((blockName, i) => (
							<FormattedMessage id={`TargetsScreen.${blockName}`} key={blockName}>
								{msg => (
									<PrettyBlockGriding xs={2} md={6}>
										<PrettyBlock label={msg} icon={<TargetIcon n={i} />} />
									</PrettyBlockGriding>
								)}
							</FormattedMessage>
						))}
					</BlocksRow>
				</Col>
			</Row>
			<Row>
				<Col md={10} offset={{ md: 1 }}>
					<Row>
						<Col md={6}>
							<h4>
								<FormattedMessage id="TargetsScreen.subheader2" />
							</h4>
							<p>
								<FormattedMessage id="TargetsScreen.text2" />
							</p>
						</Col>
					</Row>
					<BlocksRow>
						{optBlocks.map(blockName => (
							<FormattedMessage id={`TargetsScreen.${blockName}`} key={blockName}>
								{msg => (
									<FormattedMessage id={`TargetsScreen.helper.${blockName}`}>
										{tip => (
											<PrettyBlockGriding xs={2} md={3}>
												<PrettyBlock label={msg} tip={tip} isBig={true} />
											</PrettyBlockGriding>
										)}
									</FormattedMessage>
								)}
							</FormattedMessage>
						))}
					</BlocksRow>
				</Col>
			</Row>
		</TargetsScreenContainer>
	</TargetsScreenWrapper>
);

export default TargetsScreen;
