import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { linearGradient } from 'polished';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Container, Row, Col, config } from 'react-awesome-styled-grid';
import Button from 'components/Button';
import VideoScreen from 'components/VideoScreen';
import Popup from 'components/PopUp';
import OrderMessage from '../../components/forms/OrderMessage';
import TopScreenTopics from './TopScreenTopics';

const TopScreenContainer = styled(Container)`
	position: relative;
	padding-top: 5rem;
	padding-bottom: 2rem;
	${props => config(props).media['sm']`
	padding-top: 9rem;
	padding-bottom: 12rem;
	 `}
	${props => config(props).media['md']`
	padding-top: 12rem;
	padding-bottom: 8rem;
	 `}
	${props => config(props).media['lg']`
	padding-top: 12rem;
	 `}
	 @media(max-height: 650px) {
	    padding-top: 9rem;
	 }
`;

const TopScreenBg = styled.div`
	${props =>
		linearGradient({
			colorStops: [`#0B1118 0%`, `#0B1118 50%`, `${props.activeColor} 50%`, `${props.activeColor} 100%`],
			toDirection: '160deg',
			fallback: '#0B1118',
		})}
	transition: background-image 0.3s;
	color: #fff;
`;

const TopScreenDecoBg = styled.div`
	background-position: left bottom;
	background-repeat: no-repeat;
`;

const TopScreenH1 = styled.h1`
	font-size: 2.25em;
	line-height: 1.1em;
	margin-bottom: 1.5rem;
	strong {
		display: block;
		font-size: 1.35em;
		font-weight: 900;
	}
`;

const TopScreenDescription = styled.p`
	font-size: 1.3em;
	line-height: 1.3;
	margin-bottom: 1.5rem;
	padding-right: 2em;
`;

const TopScreen = ({ intl: { locale } }) => {
	const defaultHeaderColor = '#101924';
	const [state, setState] = useState({
		videoIsOpen: false,
		orderMessageFormIsOpen: false,
		headerColor: defaultHeaderColor,
	});
	return (
		<>
			<TopScreenBg activeColor={state.headerColor}>
				<TopScreenDecoBg>
					<div style={{ position: 'relative', overflow: 'hidden' }}>
						<TopScreenContainer>
							<Row>
								<Col
									md={5}
									lg={4}
									sm={12}
									offset={{ xs: 0, md: 1, lg: 1 }}
									align="flex-start"
									justify="center"
									style={{ marginBottom: 20 }}
								>
									<TopScreenH1>
										<FormattedMessage id="TopScreen.TopScreenH1" />
									</TopScreenH1>
									<TopScreenDescription>
										<FormattedMessage id="TopScreen.TopScreenDescription" />
									</TopScreenDescription>
									<Button
										href="https://passport.ubex.com/registration/?next=https://desk.ubex.com/accounts/login/?next=%2F"
										target="_blank"
										color="secondary"
									>
										<FormattedMessage id="TopScreen.StartUsing" />
									</Button>
								</Col>
								<Col md={6} lg={7} sm={12} offset={{ xs: 0, md: 0, lg: 0 }}>
									<TopScreenTopics
										onMouseEnter={color => setState({ headerColor: color })}
										onMouseLeave={() => setState({ headerColor: defaultHeaderColor })}
									/>
								</Col>
							</Row>
						</TopScreenContainer>
					</div>
					<Popup
						isOpen={state.videoIsOpen}
						width="60%"
						onClickClose={() => setState({ ...state, videoIsOpen: false })}
						closeOnClickOnWrapper
					>
						<VideoScreen />
					</Popup>
				</TopScreenDecoBg>
			</TopScreenBg>
			<OrderMessage
				isOpen={state.orderMessageFormIsOpen}
				onClose={() => setState({ ...state, orderMessageFormIsOpen: false })}
			/>
		</>
	);
};

TopScreen.propTypes = {};

export default injectIntl(TopScreen);
