import { linearGradient } from 'polished';
import colors from 'utils/colors';

export const light = linearGradient({
	colorStops: [`${colors.lightness} 0%`, `${colors.lightness} 50%`, `${colors.light} 50%`, `${colors.light} 100%`],
	toDirection: '115deg',
	fallback: '#f6f6f6',
});

export const dark = linearGradient({
	colorStops: [`${colors.dark} 0%`, `${colors.dark} 62%`, `${colors.darkness} 62%`, `${colors.darkness} 100%`],
	toDirection: '115deg',
	fallback: colors.darkness,
});
