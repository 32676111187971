export default {
	mixed: {
		required: '${path} обязателньое поле',
	},
	string: {
		email: 'Укажите адрес email',
	},
	date: {
		min: '${path} field must be later than ${min}',
		max: '${path} field must be at earlier than ${max}',
	},
};
