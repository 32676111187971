import React, { useState } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { FormattedMessage, useIntl, injectIntl } from 'react-intl';
import * as yup from 'yup';
import axios from 'axios';
import https from 'https';
import yupLocales from '../../yupLocales';
import FormField from '../FormField';
import Button from '../Button';
import { makeValidate, makeRequired } from 'utils/validation';
import Popup from '../PopUp';
import ymReachGoal from 'utils/ymReachGoal';
import gaReachGoal from 'utils/gaReachGoal';
import clientsTypes from 'clients-types';

const clientTypesIds = Object.keys(clientsTypes);
const PartnerForm = ({ onSubmit }) => {
	const intl = useIntl();
	const [isLoading, setFormSubmitRequest] = useState(false);
	const [submitSuccess, setFormSubmitSuccess] = useState(false);
	const [submitFailed, setFormSubmitFailed] = useState(null);
	yup.setLocale(yupLocales[intl.locale]);
	const validationSchema = yup.object().shape({
		first_name: yup
			.string()
			.required()
			.label(intl.formatMessage({ id: 'OrderTourForm.firstName' })),
		second_name: yup
			.string()
			.required()
			.label(intl.formatMessage({ id: 'OrderTourForm.secondName' })),
		email: yup
			.string()
			.email()
			.required()
			.label(intl.formatMessage({ id: 'OrderTourForm.email' })),
		client_type: yup
			.string() //.test({ test: value => clientTypesIds.indexOf(value.toString()) >= 0})
			.label(intl.formatMessage({ id: 'OrderTourForm.client_type' })),
		company: yup.string().label(intl.formatMessage({ id: 'PartnerForm.company' })),
	});

	const required = makeRequired(validationSchema);
	const validate = makeValidate(validationSchema);
	if (submitSuccess) {
		return (
			<h1>
				<FormattedMessage id="OrderTourForm.submitSuccess" />
			</h1>
		);
	}
	return (
		<FinalForm
			onSubmit={values => {
				setFormSubmitRequest(true);
				setFormSubmitSuccess(false);
				setFormSubmitFailed(null);
				onSubmit(values)
					.then(res => {
						setFormSubmitRequest(false);
						setFormSubmitSuccess(true);
					})
					.catch(e => {
						setFormSubmitRequest(false);
						setFormSubmitFailed(e);
					});
			}}
			validate={validate}
		>
			{({ handleSubmit }) => (
				<form onSubmit={handleSubmit}>
					{submitFailed && (
						<h1 style={{ color: 'red' }}>
							<FormattedMessage id="OrderTourForm.submitFailed" />
						</h1>
					)}
					<FormField
						required={required.first_name}
						name="first_name"
						label={<FormattedMessage id="OrderTourForm.firstName" />}
					/>
					<FormField
						required={required.second_name}
						name="second_name"
						label={<FormattedMessage id="OrderTourForm.secondName" />}
					/>
					<FormField
						required={required.email}
						name="email"
						type={'email'}
						label={<FormattedMessage id="OrderTourForm.email" />}
					/>
					<Field name="client_type" required={required.client_type}>
						{({ input, meta }) => (
							<div className="form-group">
								<label htmlFor={`client_type_input`}>
									<FormattedMessage id="OrderTourForm.client_type" />
								</label>
								<select id={`client_type_input`} className="form-control" {...input}>
									{clientTypesIds.map(cid => (
										<FormattedMessage id={`OrderTourForm.client_type_${cid}`} key={cid}>
											{msg => <option value={cid}>{msg}</option>}
										</FormattedMessage>
									))}
								</select>
								{meta.touched && meta.error && <div className="invalid-feedback">{meta.error}</div>}
							</div>
						)}
					</Field>
					<FormField
						required={required.company}
						name="company"
						label={<FormattedMessage id="PartnerForm.company" />}
					/>
					<Button as="button" type="submit" disabled={isLoading}>
						{!isLoading ? (
							<FormattedMessage id="OrderTourForm.btn" />
						) : (
							<FormattedMessage id="OrderTourForm.submitRequest" />
						)}
					</Button>
				</form>
			)}
		</FinalForm>
	);
};

const port = 3001;
const host = process.env.APP_HOST || 'http://localhost';
const agent = new https.Agent({
	rejectUnauthorized: false,
});

const PartnerPopUp = ({ isOpen, onClickClose, intl: { locale } }) => {
	return (
		<Popup isOpen={isOpen} onClickClose={onClickClose} width={'auto'}>
			<PartnerForm
				onSubmit={values => {
					ymReachGoal('form_partner', values);
					gaReachGoal('form', 'form_partner');
					return axios.post(
						`${host}:${port}`,
						{ ...values, type: 'partner', locale: locale },
						{ httpsAgent: agent },
					);
				}}
			/>
		</Popup>
	);
};

export default injectIntl(PartnerPopUp);
