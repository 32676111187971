import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { linearGradient } from 'polished';
import { Container, Row, Col, config } from 'react-awesome-styled-grid';
import colors from 'utils/colors';

const WhyScreenWrapper = styled.div`
	color: #fff;
	${linearGradient({
		colorStops: [
			`${colors.darkness} 0%`,
			`${colors.darkness} 35%`,
			`${colors.dark} 35%`,
			`${colors.dark} 75%`,
			`${colors.darkness} 75%`,
			`${colors.darkness} 100%`,
		],
		toDirection: '115deg',
		fallback: colors.dark,
	})}
`;

const WhyScreenBg = styled.div`
	background: url(/img/wave.png) center bottom no-repeat;
	background-size: contain, auto;
`;

const WhyScreenContainer = styled(Container)`
	position: relative;
	padding-top: 5rem;
	padding-bottom: 7rem;
	${props => config(props).media['md']`
		padding-top: 5rem;
		padding-bottom: 12rem;
	`}
	h3 {
		margin-bottom: 1.5em;
		font-weight: bold;
	}
	ul {
		margin-bottom: 2em;
		li {
			position: relative;
			padding-left: 1.6em;
			margin-bottom: 1.85em;
			&:before {
				content: \'\';
				width: 0.85em;
				height: 0.85em;
				position: absolute;
				left: 0em;
				margin-top: 2px;
				display: inline-block;
				border-radius: 100%;
				${linearGradient({
					colorStops: [`${colors.secondary} 0%`, `${colors.primary} 100%`],
					toDirection: '110deg',
					fallback: colors.primary,
				})}
			}
		}
	}
`;

const WhyScreen = () => (
	<WhyScreenWrapper>
		<WhyScreenBg>
			<WhyScreenContainer>
				<Row>
					<Col md={10} lg={10} offset={{ md: 1, lg: 1 }}>
						<Row reverse={['xs', 'sm']}>
							<Col md={6}>
								<h3>
									<FormattedMessage id="WhyScreen.header" />
								</h3>
								<ul>
									<li>
										<FormattedMessage id="WhyScreen.text1" />
									</li>
									<li>
										<FormattedMessage id="WhyScreen.text2" />
									</li>
									<li>
										<FormattedMessage id="WhyScreen.text3" />
									</li>
									<li>
										<FormattedMessage id="WhyScreen.text4" />
									</li>
									<li>
										<FormattedMessage id="WhyScreen.text5" />
									</li>
									<li>
										<FormattedMessage id="WhyScreen.text6" />
									</li>
									<li>
										<FormattedMessage id="WhyScreen.text7" />
									</li>
								</ul>
							</Col>
							<Col md={6} align="center" justify="center">
								<img src="/img/logo-vertical.png" style={{ marginBottom: '3em' }} />
							</Col>
						</Row>
					</Col>
				</Row>
			</WhyScreenContainer>
		</WhyScreenBg>
	</WhyScreenWrapper>
);

export default WhyScreen;
