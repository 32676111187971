export default function(category, action = 'test_dev', visitParams = '') {
	const ga = window.ga;
	if (!ga) {
		console.warn('ga function was not find!');
		return;
	}
	try {
		ga('send', 'event', category, action, visitParams);
		console.log('ym reachGoal', category, action);
	} catch (e) {
		console.error(e);
	}
}
