import React from 'react';
import styled from 'styled-components';
import { Col, Row, config } from 'react-awesome-styled-grid';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from 'components/Button';
import OrderCall from '../forms/OrderCall';

const StyledCard = styled('div')`
	background-color: #fff;
	background-image: url('/img/wave.png');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: left bottom;
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	padding-bottom: 25px;
`;

const StyledCardInner = styled('div')`
	background-repeat: no-repeat;
	background-position: calc(100% - 100px) 0px;
	${props => config(props).media['md']`
		background-image: url('/img/${props.locale === 'ru' ? 'sales-ru' : 'sales'}.png');
		padding-bottom: 150px;
        margin-bottom: -80px;
	`}
`;

const CalcCampaign = ({ intl: { locale } }) => {
	const [orderCallOpen, setOrderCallOpen] = React.useState(false);
	return (
		<>
				<StyledCardInner locale={locale}>
					<Row>
						<Col>
							<div>
								<div className="mini-header">
									<FormattedMessage id="CalcCampaign.miniheader" />
								</div>
								<h3 style={{ maxWidth: '350px' }}>
									<FormattedMessage id="CalcCampaign.header1" />
								</h3>
								<Button color="secondary" onClick={() => setOrderCallOpen(true)}>
									<FormattedMessage id="CalcCampaign.btn" />
								</Button>
							</div>
						</Col>
					</Row>
				</StyledCardInner>
			<OrderCall isOpen={orderCallOpen} onClose={() => setOrderCallOpen(false)} />
		</>
	);
};

export default injectIntl(CalcCampaign);
