import React from 'react';
import { Col, Row } from 'react-awesome-styled-grid';
import styled, { ThemeProvider } from 'styled-components';
import { FormattedMessage } from 'react-intl';

const Results = styled(Row)`
	margin-top: 0.5em;
`;
const ResultEntry = styled.div`
	background-color: #f6f6f6;
	border-radius: 5px;
	padding: ${props => props.sm ? '5px' : '5px 8px'};
	.label {
		font-size: ${props => props.sm ? '10px' : '12px'};
		font-weight: bold;
		text-transform: uppercase;
		${props => props.sm && `
			margin-bottom: 0.5em;
		`}
	}
	.val {
		color: #3069f4;
		font-size: ${props => props.sm ? '12px' : '24px'};
	}
`;
const resultsSmallGridConf = {
	gutterWidth: {
		xs: 0.222,
		sm: 0.222,
		md: 0.222,
		lg: 0.222,
		xl: 0.222,
	},
};

const CaseResults = ({ results }) => {
	if (Object.keys(results).length === 3) {
		return (
			<div>
				<strong><FormattedMessage id="Cases.results" defaultMessage="Results" />:</strong>
				<Results>
					<Col lg={4}>
						<ResultEntry>
							<div className="label">CPM</div>
							<div className="val">${results.cpm}</div>
						</ResultEntry>
					</Col>
					<Col lg={4}>
						<ResultEntry>
							<div className="label">CPC</div>
							<div className="val">${results.cpc}</div>
						</ResultEntry>
					</Col>
					<Col lg={4}>
						<ResultEntry>
							<div className="label">CTR</div>
							<div className="val">{results.ctr}%</div>
						</ResultEntry>
					</Col>
				</Results>
			</div>

		);
	}
	return (
		<ThemeProvider theme={{ awesomegrid: resultsSmallGridConf }}>
			<strong><FormattedMessage id="Cases.results" defaultMessage="Results" />:</strong>
			<Results>
				{Object.keys(results).map(key => (
					<Col lg={4}>
						<ResultEntry sm>
							<div className="label"><FormattedMessage id={`Cases.results.${key}`} defaultMessage={key.toUpperCase()} /></div>
							<div className="val">{results[key]}</div>
						</ResultEntry>
					</Col>
				))}
			</Results>
		</ThemeProvider>
	);
};

export default CaseResults;
