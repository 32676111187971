import React, { useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { ArrowBtnDown } from '../ArrowBtn';

const StyledArrowAnchor = styled(ArrowBtnDown)`
	position: fixed;
	bottom: 2rem;
	left: 50%;
	margin-left: -1.15rem;
`;

const NavLayer = ({ steps, currentStep, onSetStep }) => {
	const nextStep = currentStep + 1;
	return null;
	if (!steps[nextStep]) {
		return null;
	}
	console.log(`NavLayer.current: ${steps[currentStep]}`);
	console.log(`NavLayer.next: ${steps[nextStep]}`);
	return (
		<StyledArrowAnchor
			onClick={() => {
				onSetStep(nextStep);
				navigate(`#${steps[nextStep]}`);
			}}
		/>
	);
};
export default NavLayer;
