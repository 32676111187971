/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, title, intl: { formatMessage } }) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
					}
				}
			}
		`,
	);

	const metaDescription = description || site.siteMetadata.description;
	const ogTitle = formatMessage({ id: 'seo.og.title' });
	const ogDescription = formatMessage({ id: 'seo.og.description' });

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			titleTemplate={`%s`}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:url`,
					content: `https://ubex.com`,
				},
				{
					property: `og:title`,
					content: ogTitle,
				},
				{
					property: `og:description`,
					content: ogDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					property: `og:image`,
					content: `https://www.ubex.com/img/ubex-logo.png`,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata.author,
				},
				{
					name: `twitter:title`,
					content: title,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
				{
					name: `yandex-verification`,
					content: `0d6dd3dbc757e754`,
				},
			].concat(meta)}
		>
			<link rel="alternate" href="https://ubex.com/" hrefLang="en" key="en" />
			<link rel="alternate" href="https://ubex.com/ru/" hrefLang="ru" key="ru" />
			<link rel="alternate" href="https://ubex.com/de/" hrefLang="de" key="de" />
		</Helmet>
	);
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
};

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired,
};

export default injectIntl(SEO);
