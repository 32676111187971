import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Visible, Hidden, config } from 'react-awesome-styled-grid';
import { linearGradient } from 'polished';
import NavBar from 'components/NavBar';
import Button from 'components/Button';
import SocialLinks from 'components/SocialLinks';
import ContactsBlock from 'components/ContactsBlock';
import Overlay from 'components/Overlay';
import colors from 'utils/colors';

const SidebarWrapper = styled.div`
	width: 100%;
	border-radius: 6px;
	color: #fff;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	transform: translateX(100%);
	transition: transform 0.2s ease-out;
	z-index: 101;
	padding: 1rem 0;
	${props =>
		props.isOpen &&
		`
			transform: translateX(0);
		`}
	${linearGradient({
		colorStops: [
			`${colors.primary} 0%`,
			`${colors.primary} 62%`,
			`${colors.primaryLight} 62%`,
			`${colors.primaryLight} 100%`,
		],
		toDirection: '115deg',
		fallback: colors.primary,
	})}
	${props => config(props).media['sm']`
		padding-top: 4rem;
		padding-bottom: 2rem;
	`}
	${props => config(props).media['md']`
		max-width: 320px;
		margin: 0;
		padding-top: 6rem;
		padding-bottom: 3rem;
		border-radius: 6px 0 0 6px;
  `}
  a {
    color: inherit;
  }
`;

const SidebarBody = styled.div`
	padding: 0 1.2rem;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;
const CloseBtn = styled.div`
	position: absolute;
	top: 1rem;
	right: 1rem;
	cursor: pointer;
	font-size: 2rem;
`;

const SideBarContactsBlock = styled(ContactsBlock)`
	font-size: 12px;
	font-weight: 500;
	margin-bottom: 3rem;
`;

const SideBarSocialLinks = styled(SocialLinks)``;

const Phone = styled.div`
	font-size: 16px;
	margin: 2rem 0;
	font-weight: 500;
`;

const SideNavBar = styled(NavBar)`
	font-weight: 500;
	a {
		color: white;
		display: block;
		padding-bottom: 2rem;
	}
`;

const Sidebar = ({ onClickCloseSidebar, isOpen, intl: { locale } }) => (
	<>
		<Overlay isOpen={isOpen} onClick={() => onClickCloseSidebar()} />
		<SidebarWrapper isOpen={isOpen}>
			<CloseBtn onClick={() => onClickCloseSidebar()}>&times;</CloseBtn>
			<SidebarBody>
				<div>
					<Visible xs>
						<div style={{ marginBottom: '1.2rem' }}>
							<Button
								color="darkness"
								target="_blank"
								href="https://passport.ubex.com/login?service=https%3A%2F%2Fdesk.ubex.com%2Faccounts%2Flogin%2F%3Fnext%3D%252F"
							>
								<FormattedMessage id="Header.loginBtn" />
							</Button>
						</div>
					</Visible>
					<SideNavBar vertical={true} onClick={() => onClickCloseSidebar()} />
				</div>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					{locale === 'ru' && <Phone>+7 965 269 03 66<br />+7 926 574 34 60</Phone>}
					<div style={{ flexGrow: 1 }}>
						<SideBarContactsBlock />
					</div>
					<div>
						<SideBarSocialLinks />
					</div>
				</div>
			</SidebarBody>
		</SidebarWrapper>
	</>
);

export default injectIntl(Sidebar);
