export default function(goal = 'test_dev', visitParams = {}) {
	const ym = window.ym;
	if (!ym) {
		console.warn('ym function was not find!');
		return;
	}
	try {
		ym(48229769, 'reachGoal', goal, visitParams);
		console.log('ym reachGoal', goal);
	} catch (e) {
		console.error(e);
	}
}
