import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Layout from 'components/layout';
import SEO from 'components/seo';
import TopScreen from 'screens/screen-0-top';
import FeaturesScreen from 'screens/screen-1-features';
import TargetsScreen from 'screens/screen-2-targets';
import PartnersScreen from 'screens/screen-5-partners';
import WhyScreen from 'screens/screen-6-why';
import ClientsScreen from 'screens/screen-7-clients';
import CasesScreen from 'screens/screen-cases';
import OpportunitiesScreen from 'screens/screen-opportunities';
import LogoCarousel from '../components/LogoCarousel';

const IndexPage = ({ intl: { formatMessage } }) => {
	const title = formatMessage({ id: 'seo.title' });
	const description = formatMessage({ id: 'seo.description' });
	useEffect(() => {
		if (typeof window.ym !== 'undefined') {
			console.log('hit ym');
			window.ym(48229769, 'hit', (location || {}).href);
		}
	}, []);
	return (
		<Layout transparentHeader>
			<SEO title={title} description={description} />
			<TopScreen />
			<LogoCarousel />
			<FeaturesScreen />
			<OpportunitiesScreen />
			<TargetsScreen />
			<CasesScreen />
			{/*<TariffsScreen />*/}
			{/*<BusinessScreen />*/}
			<PartnersScreen />
			{/*<WhyScreen />*/}
			{/*<ClientsScreen />*/}
		</Layout>
	);
};

export default injectIntl(IndexPage);
