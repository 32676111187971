import React, { useState } from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Container, Row, Col, Hidden, config } from 'react-awesome-styled-grid';
import { FormattedMessage, injectIntl } from 'react-intl';
import LanguageSelector from 'components/LanguageSelector';
import Button from 'components/Button';
import colors from 'utils/colors';
import BurgerIconSVG from 'images/svg/Header/Burger.svg';
const BurgerStyledIcon = styled.svg`
	width: 1.2rem;
	height: 1.2rem;
	vertical-align: middle;
	cursor: pointer;
	use {
		opacity: 0.6;
		fill: #fff;
	}
	&:hover {
		use {
			opacity: 1;
		}
	}
`;
const BurgerIcon = () => (
	<BurgerStyledIcon>
		<use xlinkHref={`#${BurgerIconSVG.id}`} />
	</BurgerStyledIcon>
);

const HeaderWrapper = styled.div`
	background: ${props => (props.transparent ? `transparent` : colors.darkness)};
	padding-top: 1rem;
	padding-bottom: 1rem;
	position: relative;
	z-index: 5;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	color: #fff;
`;

const ControlsWrapper = styled.div`
	flex-direction: row;
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

const Phones = styled.div`
	margin-right: 1.25rem;
`;

const LogoBox = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-right: 0.5rem;
	padding-left: 0.5rem;

	& .logo-img {
		width: 80px;
		${props => config(props).media['md']`
			width: auto;
		`}
	}
`;

const LogoBorder = styled.div`
	background: linear-gradient(291.66deg, #ff1268 -24.15%, #0079ff 91.39%);
	width: 2px;
	height: 30px;
	display: inline-block;
	margin-left: 0.75rem;
	margin-right: 0.75rem;
	${props => config(props).media['md']`
		margin-left: 1.25rem;
		margin-right: 1.25rem;
	`}
`;

const LogoDescription = styled.div`
	font-size: 18px;
	line-height: 21px;
	max-width: none;
	@media (max-width: 1100px) {
		font-size: 11px;
		max-width: 2px;
		line-height: 13px;
	}
`;

const Header = ({ onClickOpenSideBar, onClickOrderCall, transparent, intl: { locale } }) => {
	const [state, setState] = useState({
		openOrderCallForm: false,
		headerStyle: {
			transition: 'all 200ms ease-in',
		},
	});
	useScrollPosition(
		({ prevPos, currPos }) => {
			const isVisible = currPos.y > prevPos.y;
			const shouldBeStyle = {
				visibility: isVisible ? 'visible' : 'hidden',
				transition: `all 200ms ${isVisible ? 'ease-in' : 'ease-out'}`,
				transform: isVisible ? 'none' : 'translate(0, -100%)',
				background: !transparent ? colors.darkness : currPos.y < -30 ? colors.darkness : 'transparent',
			};

			if (JSON.stringify(shouldBeStyle) === JSON.stringify(state.headerStyle)) return;

			setState({ ...state, headerStyle: shouldBeStyle });
		},
		[state.headerStyle],
	);
	return (
		<>
			<HeaderWrapper fuild style={{ ...state.headerStyle }} transparent={transparent}>
				<Container>
					<Row justify="space-between">
						<LogoBox align="center" direction="row">
							<Link to="/">
								<img src="/img/logo.png" alt="Ubex" className="logo-img" />
							</Link>
							<LogoBorder />
							<LogoDescription>
								<FormattedMessage
									id="Header.logoDescription"
									defaultMessage="Advertising programmatic platform"
								/>
							</LogoDescription>
						</LogoBox>
						{/*<Visible lg xl>*/}
						{/*	<Col lg={8} justify="center">*/}
						{/*		<NavBar showBuyUbex={false}/>*/}
						{/*	</Col>*/}
						{/*</Visible>*/}
						<Col align="flex-end" justify="center">
							<ControlsWrapper>
								{locale === 'ru' && (
									<Hidden xs sm>
										<Phones>
											<div>+7 965 269 03 66</div>
											<div>+7 926 574 34 60</div>
										</Phones>
									</Hidden>
								)}
								<Hidden xs sm>
									<Button small color="primary" onClick={onClickOrderCall}>
										<FormattedMessage id="Header.orderCallBtn" defaultMessage="Request a call" />
									</Button>
								</Hidden>
								<LanguageSelector />
								<span onClick={() => onClickOpenSideBar()} style={{ marginRight: '1rem' }}>
									<BurgerIcon />
								</span>
								<Hidden xs>
									<Button
										color="secondary"
										small
										href="https://passport.ubex.com/login?service=https%3A%2F%2Fdesk.ubex.com%2Faccounts%2Flogin%2F%3Fnext%3D%252F"
										target="_blank"
									>
										<FormattedMessage id="Header.loginBtn" defaultMessage="Login" />
									</Button>
								</Hidden>
							</ControlsWrapper>
						</Col>
					</Row>
				</Container>
			</HeaderWrapper>
		</>
	);
};

export default injectIntl(Header);
