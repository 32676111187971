import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import { Container, config } from 'react-awesome-styled-grid';
import Overlay from 'components/Overlay';
import { dark as gradientDark } from 'utils/gradients';
import CloseBtn from 'components/CloseBtn';
import colors from 'utils/colors';
import * as PropTypes from 'prop-types';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const rollDown = keyframes`
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const PopupWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 101;
	overflow-y: auto;
	animation: 0.3s ${fadeIn} ease-out;
`;

export const PopupBody = styled(Container)`
	padding: 1rem;
	position: relative;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
	border-radius: 8px;
	margin-top: 60px;
	animation: 0.3s ${rollDown} ease-out;
	${props =>
		props.width &&
		config(props).media['sm']`
		width: ${props.width};
	`}
	${gradientDark}
	color: ${colors.light};
`;

class Popup extends Component {
	targetElement = null;
	componentDidMount() {
		// 2. Get a target element that you want to persist scrolling for (such as a modal/lightbox/flyout/nav).
		// Specifically, the target element is the one we would like to allow scroll on (NOT a parent of that element).
		// This is also the element to apply the CSS '-webkit-overflow-scrolling: touch;' if desired.
		this.targetElement = document.querySelector('body');
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		const { isOpen } = this.props;
		if (isOpen && !prevProps.isOpen) {
			disableBodyScroll(this.targetElement);
		}
		if (!isOpen && prevProps.isOpen) {
			enableBodyScroll(this.targetElement);
		}
	}

	componentWillUnmount() {
		clearAllBodyScrollLocks();
	}

	render() {
		const { children, isOpen, onClickClose, width, closeOnClickOnWrapper, bodyStyle } = this.props;
		return !isOpen ? null : (
			<>
				<Overlay isOpen={isOpen} />
				<PopupWrapper onClick={() => closeOnClickOnWrapper && onClickClose()}>
					<PopupBody width={width} style={bodyStyle}>
						<CloseBtn onClick={() => onClickClose()}>&times;</CloseBtn>
						{children}
					</PopupBody>
				</PopupWrapper>
			</>
		);
	}
}

Popup.propTypes = {
	children: PropTypes.element,
	isOpen: PropTypes.bool,
	onClickClose: PropTypes.func,
	width: PropTypes.string,
	closeOnClickOnWrapper: PropTypes.bool,
	bodyStyle: PropTypes.object,
};

Popup.defaultProps = { closeOnClickOnWrapper: false };

export default Popup;
