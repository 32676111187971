import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { linearGradient } from 'polished';
import colors from '../../utils/colors';
import { Col, Container, Row } from 'react-awesome-styled-grid';
import OpScreenFormats from './OpScreenFormats';
const DarkScreenBg = styled.div`
	overflow: hidden;
	position: relative;
	${linearGradient({
		colorStops: [
			`${colors.darkness} 0%`,
			`${colors.darkness} 35%`,
			`${colors.dark} 35%`,
			`${colors.dark} 75%`,
			`${colors.darkness} 75%`,
			`${colors.darkness} 100%`,
		],
		toDirection: '-115deg',
		fallback: colors.dark,
	})}
	color: white;
`;

const DarkScreenContainer = styled(Container)`
	padding-top: 6em;
	padding-bottom: 7em;
	h3 {
		font-weight: bold;
	}
`;

const OpCard = styled.div`
	background: url(/img/op/card-bg.svg) center 70px no-repeat,
		linear-gradient(291.66deg, #ff1268 -24.15%, #0079ff 91.39%);
	background-size: cover;
	border-radius: 5px;
	padding: 1em;
	padding-bottom: 120px;
	position: relative;
	overflow: hidden;
	margin-bottom: 1em;
	h4 {
		position: relative;
		z-index: 2;
		height: 2em;
	}
	& .icon {
		background-color: white;
		border-radius: 100%;
		width: 53px;
		height: 53px;
		line-height: 53px;
		text-align: center;
		margin-bottom: 1em;
	}
	& .deco {
		position: absolute;
		right: 1em;
		bottom: 10px;
		z-index: 1;
	}
`;

const OpportunitiesScreen = () => {
	return (
		<DarkScreenBg>
			<DarkScreenContainer>
				<Row>
					<Col md={10} offset={{ md: 1 }}>
						<h3 style={{ marginBottom: '1.2em', maxWidth: '400px' }} id="inventory">
							<FormattedMessage
								id="OpportunitiesScreen.header0"
								values={{
									s: (...chunks) => <span>{chunks}</span>,
								}}
							/>
						</h3>
						<OpScreenFormats />
					</Col>
				</Row>
				<Row>
					<Col md={10} offset={{ md: 1 }}>
						<h3 style={{ marginBottom: '1em' }}>
							<FormattedMessage id="OpportunitiesScreen.header" />
						</h3>
						<Row>
							<Col sm={3}>
								<OpCard>
									<div className="icon">
										<img src="/img/op/icon-1.svg" alt="" />
									</div>
									<h4>
										<FormattedMessage id="OpportunitiesScreen.header1" />
									</h4>
									<img
										src="/img/op/deco-1.png"
										className="deco"
										alt=""
										style={{ marginBottom: -30 }}
									/>
								</OpCard>
							</Col>
							<Col sm={3}>
								<OpCard>
									<div className="icon">
										<img src="/img/op/icon-2.svg" alt="" />
									</div>
									<h4>
										<FormattedMessage id="OpportunitiesScreen.header2" />
									</h4>
									<img src="/img/op/deco-2.png" className="deco" alt="" />
								</OpCard>
							</Col>
							<Col sm={3}>
								<OpCard>
									<div className="icon">
										<img src="/img/op/icon-3.svg" alt="" />
									</div>
									<h4>
										<FormattedMessage id="OpportunitiesScreen.header3" />
									</h4>
									<img src="/img/op/deco-3.png" className="deco" alt="" />
								</OpCard>
							</Col>
							<Col sm={3}>
								<OpCard>
									<div className="icon">
										<img src="/img/op/icon-4.svg" alt="" />
									</div>
									<h4>
										<FormattedMessage id="OpportunitiesScreen.header4" />
									</h4>
									<img src="/img/op/deco-4.png" className="deco" alt="" />
								</OpCard>
							</Col>
						</Row>
						<Row>
							<Col sm={3}>
								<OpCard>
									<div className="icon">
										<img src="/img/op/icon-5.svg" alt="" />
									</div>
									<h4>
										<FormattedMessage id="OpportunitiesScreen.header5" />
									</h4>
									<img src="/img/op/deco-5.png" className="deco" alt="" />
								</OpCard>
							</Col>
							<Col sm={3}>
								<OpCard>
									<div className="icon">
										<img src="/img/op/icon-6.svg" alt="" />
									</div>
									<h4>
										<FormattedMessage id="OpportunitiesScreen.header6" />
									</h4>
									<img src="/img/op/deco-6.png" className="deco" alt="" />
								</OpCard>
							</Col>
							<Col sm={3}>
								<OpCard>
									<div className="icon">
										<img src="/img/op/icon-7.svg" alt="" />
									</div>
									<h4>
										<FormattedMessage id="OpportunitiesScreen.header7" />
									</h4>
									<img
										src="/img/op/deco-7.png"
										className="deco"
										alt=""
										style={{ marginBottom: -20 }}
									/>
								</OpCard>
							</Col>
							<Col sm={3}>
								<OpCard>
									<div className="icon">
										<img src="/img/op/icon-8.svg" alt="" />
									</div>
									<h4>
										<FormattedMessage id="OpportunitiesScreen.header8" />
									</h4>
									<img src="/img/op/deco-8.png" className="deco" alt="" />
								</OpCard>
							</Col>
						</Row>
					</Col>
				</Row>
			</DarkScreenContainer>
		</DarkScreenBg>
	);
};

export default OpportunitiesScreen;
