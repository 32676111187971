import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

const FormField = ({ name, label, type = 'text', placeholder, ...props }) => (
	<Field name={name} {...props}>
		{({ input, meta }) => (
			<div className="form-group">
				{label && <label htmlFor={`${name}_input`}>{label}</label>}
				<input type={type} id={`${name}_input`} className="form-control" placeholder={placeholder} {...input} />
				{meta.touched && meta.error && <div className="invalid-feedback">{meta.error}</div>}
			</div>
		)}
	</Field>
);
export const FormFieldTextarea = ({ name, label, type = 'text', placeholder, ...props }) => (
	<Field name={name} {...props}>
		{({ input, meta }) => (
			<div className="form-group">
				{label && <label htmlFor={`${name}_input`}>{label}</label>}
				<textarea id={`${name}_input`} className="form-control" placeholder={placeholder} {...input} />
				{meta.touched && meta.error && <div className="invalid-feedback">{meta.error}</div>}
			</div>
		)}
	</Field>
);

FormField.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType, PropTypes.object]),
	type: PropTypes.oneOf(['text', 'tel', 'email', 'number', 'password']),
	placeholder: PropTypes.string,
};

export default FormField;
