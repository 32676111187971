/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './src/styles/reset.scss';
import './src/styles/global.scss';
import './src/styles/datetime-field.css';
import 'slick-carousel/slick/slick.css';
import { getCookie, setCookie } from './src/utils/cookieHelper';

const langs = ['ru'];
export const onClientEntry = () => {
	const USER_LOCALE = navigator.language.slice(0, 2) || navigator.userLanguage.slice(0, 2) || 'en';
	const savedLang = getCookie('ubex-lang');
	if (!savedLang && langs.indexOf(USER_LOCALE) >= 0) {
		setCookie('ubex-lang', USER_LOCALE);
	}
	if (USER_LOCALE !== 'en' && !savedLang && langs.indexOf(USER_LOCALE) >= 0) {
		window.location = `/${USER_LOCALE}`;
	}
};
