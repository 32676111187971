import styled from 'styled-components';

const CloseBtn = styled.div`
	position: absolute;
	top: -2.5rem;
	right: 1rem;
	cursor: pointer;
	font-size: 2rem;
	z-index: 102;
	color: ${props => (!props.color ? 'inherit' : props.color)};
	${props =>
		!props.shadow
			? null
			: `
		text-shadow: 0px 2px 4px rgba(0,0,0,.5);
	`}
`;

export default CloseBtn;
